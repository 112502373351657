import {
  AppstoreAddOutlined,
  DownOutlined,
  PlusOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { Button, Popover, Space, Typography } from 'antd';
import { ExecutionStrategy, Rule } from 'digicust_types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '../../../../Components/Inputs/SearchInput';
import RuleCatalogContent from './Catalog/RuleCatalogContent';
import RuleCatalogModal from './Catalog/RuleCatalogModal';
import RuleComponent from './Rule';

const RulesTab = ({
  value,
  onValueChange,
}: {
  value: ExecutionStrategy;
  onValueChange: (value: ExecutionStrategy) => void;
}) => {
  const [search, setSearch] = useState('');

  const { t } = useTranslation();

  const [catalogModalOpen, setCatalogModalOpen] = useState(false);

  const moveRule = (index: number, direction: 'up' | 'down') => {
    const newRules = [...(value.rules || [])];
    if (direction === 'up' && index > 0) {
      [newRules[index], newRules[index - 1]] = [
        newRules[index - 1],
        newRules[index],
      ];
    } else if (direction === 'down' && index < newRules.length - 1) {
      [newRules[index], newRules[index + 1]] = [
        newRules[index + 1],
        newRules[index],
      ];
    }
    onValueChange({ ...value, rules: newRules });
  };

  return (
    <Space
      direction="vertical"
      style={{
        width: '100%',
        maxHeight: 'calc(100vh - 160px)',
        overflowY: 'auto',
      }}
    >
      <Space>
        <SearchInput onChange={setSearch} />
        <Button
          icon={<PlusOutlined />}
          onClick={() => {
            const newRules: Rule[] = [
              ...(value.rules || []),
              {
                name: 'New Rule',
                conditions: [{}],
                userInput: {
                  priority: 'afterNormalization',
                },
              },
            ];
            onValueChange({ ...value, rules: newRules });
          }}
        >
          {t('Add Rule')}
        </Button>
        <Button
          icon={<AppstoreAddOutlined />}
          onClick={() => setCatalogModalOpen(true)}
        >
          {t('From Catalog')}
        </Button>
        <RuleCatalogModal
          onUseRule={(rule) => {
            onValueChange({ ...value, rules: [...(value.rules || []), rule] });
          }}
          open={catalogModalOpen}
          onOpenChange={setCatalogModalOpen}
        />
      </Space>

      <Space direction="vertical" style={{ width: '99%' }}>
        {(value?.rules?.length || 0) > 0 ? (
          value?.rules
            ?.filter(
              (rule) =>
                !search ||
                JSON.stringify(rule)
                  .toLowerCase()
                  .includes(search?.toLowerCase()),
            )
            ?.map((rule, index) => (
              <RuleComponent
                value={rule}
                moveComponent={
                  <Popover
                    content={t('Higher rules have higher priority')}
                    placement="left"
                  >
                    <Space>
                      <Button.Group>
                        <Button
                          icon={<UpOutlined />}
                          size="small"
                          disabled={index === 0}
                          onClick={() => moveRule(index, 'up')}
                        />
                        <Button
                          icon={<DownOutlined />}
                          size="small"
                          disabled={index === (value.rules?.length || 1) - 1}
                          onClick={() => moveRule(index, 'down')}
                        />
                      </Button.Group>
                    </Space>
                  </Popover>
                }
                onDelete={() => {
                  const newRules = [...(value.rules || [])];
                  newRules.splice(index, 1);
                  onValueChange({ ...value, rules: newRules });
                }}
                onCopy={() => {
                  const newRules = [
                    ...(value.rules || []),
                    { ...(rule || {}), name: `Copy of ${rule?.name}` },
                  ];
                  onValueChange({ ...value, rules: newRules });
                }}
                onValueChange={(e) => {
                  const newRules = [...(value.rules || [])];
                  newRules[index] = e;
                  onValueChange({ ...value, rules: newRules });
                }}
              />
            ))
        ) : (
          <Typography.Paragraph style={{ maxWidth: 800 }}>
            {t(
              `Streamline your customs procedures with Rules. Set conditions based on customs requirements and trigger automatic actions, such as setting the correct Incoterm or Procedures. Simplify customs compliance and save time with our powerful Rules engine. Rules apply to all future customs cases processed with this execution strategy.`,
            )}
          </Typography.Paragraph>
        )}
      </Space>

      <div style={{ marginTop: 50 }}>
        <RuleCatalogContent
          onUseRule={(rule) => {
            onValueChange({ ...value, rules: [...(value.rules || []), rule] });
          }}
        />
      </div>
    </Space>
  );
};

export default RulesTab;
