import { LoadingOutlined, SendOutlined, UserOutlined } from '@ant-design/icons';
import { useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Avatar, Button, Card, Flex, Input, Space, Typography } from 'antd';
import {
  TariffSystem,
  TariffWSMessage,
  TariffWSMessageSender,
  TariffWSMessageType,
} from 'digicust_types';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WiStars } from 'react-icons/wi';
import Markdown from 'react-markdown';
import { config } from '../../config';
import { useAppDataContext } from '../AppDataProvider';
import { ChatTariffNumber } from './ChatTariffNumber';

const ChatComponentImpl = ({
  apply,
  onChange,
}: {
  height?: string;
  apply?: boolean;
  onChange?: (value: string) => void;
}) => {
  const { t, i18n } = useTranslation();
  const { customerId, projectId, projectDetails } = useAppDataContext();

  const tariff_system =
    projectDetails?.tariffNumberTreeSystem || TariffSystem.DE;
  const language = i18n.language as 'en' | 'de';

  const idObject = {
    language,
    tariff_system,
    customer_id: customerId,
    project_id: projectId,
  };

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [streaming, setStreaming] = useState(false);
  const [messages, setMessages] = useState<TariffWSMessage[]>([]);

  const [inputValue, setInputValue] = useState('');
  const messagesRef = useRef(messages);
  const chatEndRef = useRef<HTMLDivElement>(null);
  const [ws, setWs] = useState<WebSocket>();
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const idToken = account?.idToken || localStorage.getItem('tokon');

  const addMessage = (message: string, sender: TariffWSMessageSender) => {
    if (message === '') return;
    setMessages((prevMessages) => [
      ...prevMessages,
      { message, sender, ...idObject },
    ]);
  };

  const sendMessage = (message: string) => {
    if (ws) {
      const sendMessage: TariffWSMessage = {
        message,
        sender: TariffWSMessageSender.user,
        ...idObject,
      };

      ws.send(JSON.stringify(sendMessage));
      setButtonDisabled(true);
    } else {
      console.log('No WebSocket connection!');
    }
  };

  const handleSendClick = () => {
    if (!inputValue) return;
    addMessage(inputValue, TariffWSMessageSender.user);
    setInputValue('');
    setTimeout(() => {
      sendMessage(inputValue);
    }, 100);
  };

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    messagesRef.current = messages;
  }, [messages]);

  useEffect(() => {
    setMessages([
      {
        message: t(
          'Hi! I am Taric, your personal customs expert. I can help you with customs tariff classifications.',
        ),
        sender: TariffWSMessageSender.bot,
        ...idObject,
      },
      {
        message: t(
          'How about you give it a try by giving me a goods description?',
        ),
        sender: TariffWSMessageSender.bot,
        ...idObject,
      },
    ]);
  }, [customerId, projectId]);

  useEffect(() => {
    setTimeout(scrollToBottom, 300);
  }, [messages]);

  useEffect(() => {
    if (account) {
      instance.acquireTokenSilent({
        ...config.msalRequest,
        account,
      });
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (idToken) {
      const endpoint = `wss://dev.api.digicust.com/tariff_classification/ws?token=${idToken}`;
      const newWs = new WebSocket(endpoint);

      newWs.onmessage = (event) => {
        const data = JSON.parse(event.data);
        const currentMessages = messagesRef.current || [];

        if (data.sender === 'bot') {
          const updatedMessages = [...currentMessages];
          if (data.type === 'start' || data.type === 'error') {
            updatedMessages.push({
              message: data.message || '',
              sender: TariffWSMessageSender.bot,
              ...idObject,
            });
          } else if (data.type === 'stream') {
            setButtonDisabled(true);
            setStreaming(true);

            const lastMessage = updatedMessages[updatedMessages.length - 1];
            if (lastMessage && lastMessage.sender === 'bot') {
              lastMessage.message += data.message;
              const regex = /\b(?:\d\.? ?){6,15}\b/g;
              let match;
              while ((match = regex.exec(lastMessage.message)) !== null) {
                const matchedNumber = match[0].replace(/\./g, '');
                if (matchedNumber.length >= 6 && matchedNumber.length <= 11) {
                  lastMessage.number = matchedNumber;
                  lastMessage.type = TariffWSMessageType.tariffNumber;
                }
              }
            } else {
              updatedMessages.push({
                message: data.message,
                sender: TariffWSMessageSender.bot,
                ...idObject,
              });
            }
          } else if (data.type === 'end') {
            setButtonDisabled(false);
            setStreaming(false);
          }
          setMessages(updatedMessages);
        }
      };

      newWs.onopen = () => {
        setWs(newWs);
        console.log('WebSocket connection is open.');
      };
      newWs.onerror = (error) => {
        console.error(`WebSocket Error: ${error}`);
      };

      return () => newWs.close();
    }
  }, []);

  return (
    <Flex vertical style={{ height: '100%' }}>
      <Space
        direction="vertical"
        style={{ flex: 1, marginBottom: 70 }}
        test-id="taric-chat-container"
      >
        {messages.map((item, index) => (
          <Space key={index}>
            {item.sender === 'bot' ? (
              <Avatar
                size="small"
                style={{ backgroundColor: '#e9eef5' }}
                icon={<WiStars size={24} style={{ color: '#1677ff' }} />}
              />
            ) : (
              <Avatar
                size="small"
                style={{ backgroundColor: '#1677ff' }}
                icon={<UserOutlined />}
              />
            )}
            <Card
              bodyStyle={{ padding: 10, backgroundColor: '#fafafa' }}
              style={{ maxWidth: '800px' }}
            >
              <Space direction="vertical" size={5}>
                {item.message ? (
                  <Markdown
                    children={item.message}
                    skipHtml
                    components={{ p: 'span' }}
                  />
                ) : (
                  <Typography.Text type="secondary">
                    {t('Thinking...')}
                  </Typography.Text>
                )}
              </Space>
              {item.type === 'tariffNumber' && item.number && (
                <ChatTariffNumber
                  number={item.number}
                  apply={apply}
                  onChange={onChange}
                />
              )}
              {index === messages.length - 1 && streaming && (
                <LoadingOutlined />
              )}
            </Card>
          </Space>
        ))}
      </Space>

      <Flex
        gap={10}
        style={{
          position: 'sticky',
          bottom: 0,
          paddingTop: '10px',
          background: 'white',
        }}
      >
        <Input
          size="large"
          autoFocus
          test-id="taric-chat-input"
          placeholder={t('Classify "leather shoes"')}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onPressEnter={handleSendClick}
          disabled={!ws || buttonDisabled}
        />
        <Button
          size="large"
          onClick={handleSendClick}
          icon={<SendOutlined />}
          test-id="taric-chat-send"
          disabled={!ws || buttonDisabled}
        >
          {t('Send')}
        </Button>
      </Flex>

      <div
        style={{
          textAlign: 'center',
          height: '40px',
          paddingTop: '5px',
        }}
      >
        {!ws ? (
          <Flex
            gap={4}
            align="center"
            justify="center"
            style={{ fontSize: '10px' }}
          >
            <LoadingOutlined />
            Establishing connection to taric system
          </Flex>
        ) : (
          ''
        )}
      </div>
    </Flex>
  );
};

export default ChatComponentImpl;
