import {
  DeleteOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined,
  GroupOutlined,
  UngroupOutlined,
} from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Flex,
  message,
  Modal,
  Space,
  TablePaginationConfig,
  Typography,
} from 'antd';
import { LineItemModel } from 'digicust_types';
import { Key } from 'react';
import { useTranslation } from 'react-i18next';
import uuid from 'react-uuid';
import { downloadJSONAsXLSX } from '../../../utils/helpers/downloadAsXLSX';
import ClassifyLineItemButton from './ClassifyLineItem';
import { CopiedData } from './hooks/useCopyHook';

export type NewLineItem = LineItemModel & {
  key: string;
};

export default function LineTableHeader({
  dense,
  setDense,
  aggregate,
  setAggregate,
  selectedRowKeys,
  setSelectedRowKeys,
  allSelected,
  setAllSelected,
  selectedItems,
  handlePaste,
  copiedData,
  clearCopiedData,
  lineItems,
  setLineItems,
  setPaginated,
}: {
  dense: boolean;
  setDense: React.Dispatch<React.SetStateAction<boolean>>;
  aggregate: boolean;
  setAggregate: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRowKeys: Key[];
  setSelectedRowKeys: React.Dispatch<React.SetStateAction<Key[]>>;
  allSelected: boolean;
  setAllSelected: React.Dispatch<React.SetStateAction<boolean>>;
  selectedItems: LineItemModel[];
  handlePaste: (items: LineItemModel[]) => Promise<void>;
  copiedData: CopiedData;
  clearCopiedData: React.Dispatch<React.SetStateAction<void>>;
  lineItems: LineItemModel[];
  setLineItems: (value: LineItemModel[]) => void;
  setPaginated: React.Dispatch<React.SetStateAction<TablePaginationConfig>>;
}) {
  const { t } = useTranslation();

  const handleAddEmptyRow = () => {
    setPaginated((prev) => ({ ...prev, current: 1 }));

    setLineItems([{ id: uuid(), key: 'new' } as NewLineItem, ...lineItems]);
    message.info(t('Added item at index 1'));
  };

  return (
    <Space>
      {Object.keys(copiedData).length > 0 && (
        <Space
          style={{
            padding: 3,
            borderRadius: '5px',
            outline: '2px blue solid',
            background: '#e6f4ff',
            float: 'left',
          }}
        >
          <Button
            type="text"
            size="small"
            title={t('Clear copied data')}
            onClick={() => {
              message.info('Data cleared');
              clearCopiedData();
            }}
          >
            {t('Clear')}
          </Button>

          {selectedRowKeys.length > 0 && (
            <Button
              type="primary"
              size="small"
              title={t('Apply copied data to selected items')}
              onClick={() => {
                message.success('Item updated');
                handlePaste(selectedItems);
              }}
            >
              {t('Apply')}
            </Button>
          )}
        </Space>
      )}

      {(selectedRowKeys?.length || allSelected) && (
        <Flex
          gap={5}
          align="center"
          style={{
            padding: 3,
            borderRadius: '5px',
            background: '#e6f4ff',
          }}
        >
          <Typography.Text style={{ whiteSpace: 'nowrap' }}>
            {allSelected
              ? `${t('Total')} ${lineItems.length} ${
                  lineItems.length === 1
                    ? t('item selected')
                    : t('items selected')
                }`
              : `${selectedRowKeys?.length} ${
                  lineItems.length === 1
                    ? t('item selected')
                    : t('items selected')
                }`}
          </Typography.Text>
          <Button
            type="text"
            size="small"
            title={t('Clear')}
            onClick={() => {
              setSelectedRowKeys([]);
              setAllSelected(false);
            }}
          >
            {t('Clear')}
          </Button>
          <Button
            type="text"
            size="small"
            title={t('Delete')}
            onClick={() => {
              Modal.confirm({
                title: t('Do you want to delete these items?'),
                icon: <ExclamationCircleOutlined />,
                content: t('This step is irreversible.'),
                onOk: () => {
                  const updatedValue = lineItems.filter(
                    (item) => !selectedRowKeys.includes(item?.id as Key),
                  );

                  setLineItems(updatedValue);
                  setSelectedRowKeys([]);
                },
              });
            }}
            icon={<DeleteOutlined />}
          />

          <ClassifyLineItemButton
            lineItemIds={selectedItems.map((c) => ({
              id: c.id!,
              barCode: c.code?.value || c.materialNumber?.value,
            }))}
            onSuccess={(lineItemIds) => {
              const newLineItems = lineItems.map((item) => {
                if (!lineItemIds.includes(item.id || '')) {
                  return item;
                }

                return {
                  ...(item || {}),
                  customsTariffNumber: {
                    ...(item.customsTariffNumber || {}),
                    taric: {
                      ...(item.customsTariffNumber?.taric || {}),
                      toBeProcessed: true,
                    },
                  },
                };
              });

              setLineItems(newLineItems);
              setSelectedRowKeys([]);
            }}
          />
        </Flex>
      )}

      <Button onClick={handleAddEmptyRow}>{t('Add')}</Button>

      {dense ? (
        <Checkbox
          checked={aggregate}
          onChange={(e) => setAggregate(e.target.checked)}
        >
          {t('Aggregate')}
        </Checkbox>
      ) : null}

      <Button
        title={t('Download items')}
        type="text"
        onClick={() =>
          downloadJSONAsXLSX(
            lineItems?.map((item) => ({
              description: item?.description?.value,
              tariffNumber: item?.customsTariffNumber?.value,
              materialNumber: item?.materialNumber?.value,
              articleNumber: item?.code?.value,
              orderNumber: item?.orderNumber?.value,
              buyerArticleNumber: item?.buyerArticleNumber?.value,
              buyerOrderNumber: item?.buyerOrderNumber?.value,
              totalNetWeight: item?.totalNetWeight?.value,
              totalNetWeightUnit: item?.totalNetWeight?.unit,
              totalGrossWeight: item?.totalGrossWeight?.value,
              totalGrossWeightUnit: item?.totalGrossWeight?.unit,
              quantity: item?.quantity?.value,
              quantityUnit: item?.quantity?.uom?.DE_I0700,
              countryOfOrigin: item?.countryOfOrigin?.alpha2Code,
              preference: item?.preferenceState?.DE_A1200,
              totalValue: item?.totalValue?.value,
              totalValueCurrency: item?.totalValue?.unit,
              specialUnit: item?.itemSpecialUnit?.DE_I0700,
              specialUnitMeasure: item?.itemSpecialUnitMeasure?.value,
              documents: item?.documents
                ?.map(
                  (doc) => `${doc?.documentCode?.DE_I0200}:${doc?.reference}`,
                )
                ?.join(', '),
            })),
            'Items.xlsx',
          )
        }
        icon={<DownloadOutlined />}
      />

      <Button
        title={dense ? t('Detailed') : t('Compact')}
        type="text"
        onClick={() => setDense(!dense)}
        icon={dense ? <GroupOutlined /> : <UngroupOutlined />}
      />
    </Space>
  );
}
