import { Checkbox, Form, Input } from 'antd';
import { DakosySftpConfig } from 'digicust_types';
import { useTranslation } from 'react-i18next';

export default function DakosySystemForm({
  config,
  changeData,
}: {
  config: DakosySftpConfig;
  changeData: (key: string, value: string | boolean) => void;
}) {
  const { t } = useTranslation();

  return (
    <Form>
      <Form.Item label={t('DNS Name')}>
        <Input
          placeholder={t('DNS Name')}
          value={config?.['sftp-dns-name']}
          onChange={(e) => changeData('sftp-dns-name', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('Port')}>
        <Input
          placeholder={t('Port')}
          value={config?.['sftp-port']}
          onChange={(e) => changeData('sftp-port', e.target.value)}
        />
      </Form.Item>

      <Form.Item label={t('Login name')}>
        <Input
          placeholder={t('Login name')}
          value={config?.['sftp-login-name']}
          onChange={(e) => changeData('sftp-login-name', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('Password')}>
        <Input.Password
          placeholder={t('Password')}
          value={config?.['sftp-password']}
          onChange={(e) => changeData('sftp-password', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('Inbound')}>
        <Input
          placeholder={t('Inbound')}
          value={config?.['sftp-inbound']}
          onChange={(e) => changeData('sftp-inbound', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('Outbound')}>
        <Input
          placeholder={t('Outbound')}
          value={config?.['sftp-outbound']}
          onChange={(e) => changeData('sftp-outbound', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('Partner ID')}>
        <Input
          placeholder={t('Partner ID')}
          value={config?.['partner-id']}
          onChange={(e) => changeData('partner-id', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('Division')}>
        <Input
          placeholder={t('Division')}
          value={config?.['division']}
          onChange={(e) => changeData('division', e.target.value)}
        />
      </Form.Item>

      <Form.Item label={t('No Documents Types on Head')}>
        <Checkbox
          checked={config?.['noDocumentsOnHead']}
          onChange={(e) => changeData('noDocumentsOnHead', e.target.checked)}
        />
      </Form.Item>
      <Form.Item label={t('Aggregate Line Items (Import/Export only)')}>
        <Checkbox
          checked={config?.['aggregateItems']}
          onChange={(e) => changeData('aggregateItems', e.target.checked)}
        />
      </Form.Item>
      <Form.Item label={t('Template (optional)')}>
        <Input
          placeholder="Template"
          value={config?.['template']}
          onChange={(e) => changeData('template', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('Ignore DV1')}>
        <Checkbox
          checked={config?.['ignoreDV1']}
          onChange={(e) => changeData('ignoreDV1', e.target.checked)}
        />
      </Form.Item>
      <Form.Item label={t('Ignore Destination Address')}>
        <Checkbox
          checked={config?.['ignoreToAddress']}
          onChange={(e) => changeData('ignoreToAddress', e.target.checked)}
        />
      </Form.Item>
      <Form.Item label={t('Enforce "Import Declarant is Recipient"')}>
        <Checkbox
          checked={config?.['enforceImportDeclarantIsRecipient']}
          onChange={(e) =>
            changeData('enforceImportDeclarantIsRecipient', e.target.checked)
          }
        />
      </Form.Item>
    </Form>
  );
}
