import { Input, Select, Space } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { AdditionDeduction } from 'digicust_types';
import { BaseSelectRef } from 'rc-select';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrencies } from '../../utils/useCodes';

const FreightCostDistributionInput = ({
  value = {},
  onChange,
}: {
  value?: AdditionDeduction;
  onChange: (value: AdditionDeduction) => void;
}) => {
  const { t } = useTranslation();

  // https://www.einfuhr.internetzollanmeldung.de/iza/codierungen/EINFUHR/10.1/xml/A1070.xml
  const options: DefaultOptionType[] = [
    { label: 'R', value: 'R' },
    { label: 'LK', value: 'LK' },
    {
      label: '001',
      value: '001',
      description: t(
        'Costs incurred by the buyer (commissions) (excluding purchase commissions)',
      ),
    },
    {
      label: '002',
      value: '002',
      description: t('Costs incurred by the buyer (broker fees)'),
    },
    {
      label: '003',
      value: '003',
      description: t('Costs incurred by the buyer (wrapping and packaging)'),
    },
    {
      label: '004',
      value: '004',
      description: t('Costs incurred by the buyer (wrapping and packaging)'),
    },
    {
      label: '005',
      value: '005',
      description: t(
        'Items and services provided by the buyer free of charge or at reduced prices for use in connection with the production and sale for export of the imported goods. The listed values are divided accordingly if necessary. (Tools, molds, and similar items used in the production of the imported goods)',
      ),
    },
    {
      label: '006',
      value: '006',
      description: t(
        'Items and services provided by the buyer free of charge or at reduced prices for use in connection with the production and sale for export of the imported goods. The listed values are divided accordingly if necessary. (Materials consumed in the production of the imported goods)',
      ),
    },
    {
      label: '007',
      value: '007',
      description: t(
        'Items and services provided by the buyer free of charge or at reduced prices for use in connection with the production and sale for export of the imported goods. The listed values are divided accordingly if necessary. (Techniques, developments, designs, plans, and sketches necessary for the production of the imported goods that were developed outside the Community)',
      ),
    },
    {
      label: '008',
      value: '008',
      description: t('License fees'),
    },
    {
      label: '009',
      value: '009',
      description: t(
        'Proceeds from resale, other transfers or uses that benefit the seller, delivery costs to the place of delivery',
      ),
    },
    {
      label: '010',
      value: '010',
      description: t(
        'Delivery costs to the place of delivery (transportation)',
      ),
    },
    {
      label: '011',
      value: '011',
      description: t(
        'Delivery costs to the place of delivery (loading and handling charges)',
      ),
    },
    {
      label: '012',
      value: '012',
      description: t('Delivery costs to the place of delivery (insurance)'),
    },
    {
      label: '014',
      value: '014',
      description: t('Transport costs after arrival at the place of delivery'),
    },
    {
      label: '015',
      value: '015',
      description: t(
        'Payments for construction, erection, assembly, maintenance, or technical support after importation',
      ),
    },
    {
      label: '016',
      value: '016',
      description: t('Other payments (type)'),
    },
    {
      label: '017',
      value: '017',
      description: t(
        'Duties and taxes to be paid in the Community due to the import or sale of the goods',
      ),
    },
    {
      label: '019',
      value: '019',
      description: t(
        'The value is to be transmitted if the proportional delivery costs cannot be determined via the corresponding fields "type of deduction/addition" with the contents "010", "011" or "012"',
      ),
    },
    {
      label: '020',
      value: '020',
      description: t('Total air freight costs'),
    },
  ];

  const [curValue, setCurValue] = useState<number>();

  useMemo(() => {
    if (value?.amount) setCurValue(value?.amount);
  }, [value?.amount]);

  const selectRef = useRef<BaseSelectRef>(null);
  const currencies = useCurrencies();

  return (
    <Space.Compact style={{ width: '100%', borderRadius: 6 }}>
      <Select
        ref={selectRef}
        showSearch
        style={{ width: '50%' }}
        placeholder={t('Cost Type')}
        options={options}
        value={value?.type}
        onChange={(e) => onChange({ ...value, type: e })}
      />

      <Input
        style={{ width: '50%' }}
        placeholder={t('Value')}
        type="number"
        value={curValue}
        onChange={(e) => {
          setCurValue(Number(e.target.value));
        }}
        onBlur={() => onChange({ ...value, amount: Number(curValue) })}
      />

      <Select
        ref={selectRef}
        style={{ width: '150px' }}
        placeholder={t('Currency')}
        showSearch
        value={value?.currency}
        onChange={(e) => onChange({ ...value, currency: e })}
      >
        {currencies
          ?.filter((item) => item?.code)
          ?.map((item, key) => (
            <Select.Option
              title={item.name}
              key={key}
              value={item.code}
              label={item.name}
            >
              {item?.code}
            </Select.Option>
          ))}
      </Select>
    </Space.Compact>
  );
};

export default FreightCostDistributionInput;
