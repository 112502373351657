import { LockOutlined } from '@ant-design/icons';
import { Checkbox, Form, Input, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { ExecutionStrategy } from 'digicust_types';
import { useTranslation } from 'react-i18next';
import { WiStars } from 'react-icons/wi';
import { DCTooltip } from '../../../../common';
import { useAppDataContext } from '../../../AppDataProvider';
import CardWrapper from '../../Common/CardWrapper';

export const DataExtractionCard = ({
  value,
  onValueChange,
}: {
  value: ExecutionStrategy;
  onValueChange: (val: ExecutionStrategy) => void;
}) => {
  const { isAdmin } = useAppDataContext();
  const { t } = useTranslation();

  return (
    <CardWrapper
      title={t('Data Extraction')}
      alternative
      tooltip={t(
        'Specify how data shall be extracted from documents. If you deactivate this, no data will be extracted from documents.',
      )}
      description={t('Specify how Digicust extracts data from documents.')}
      checked={value?.dataExtraction?.active}
      onChange={(e) =>
        onValueChange({
          ...value,
          dataExtraction: {
            ...(value.dataExtraction || {}),
            active: e,
          },
        })
      }
    >
      {value.dataExtraction?.active && (
        <Form layout="vertical">
          <div className="hoverable">
            {t('Engine')}
            <DCTooltip
              text={t(
                'Different engines have different advantages and disadvantages. Dexter IDP Elevator is fast, but less reliable than Dexter IDP Escalator.',
              )}
            />
            <Select
              popupMatchSelectWidth={false}
              size="small"
              placeholder={t('Engine')}
              optionFilterProp="children"
              value={value?.dataExtraction?.engine || 'default'}
              onChange={(e) =>
                onValueChange({
                  ...value,
                  dataExtraction: {
                    ...(value.dataExtraction || {}),
                    engine: e || 'default',
                  },
                })
              }
            >
              <Select.Option value={'default'}>
                Dexter IDP Escalator
              </Select.Option>
              <Select.Option value={'dexter_experimental'}>
                Dexter IDP Elevator
              </Select.Option>
            </Select>
          </div>
          <Checkbox
            className="hoverable"
            checked={value.dataNormalization?.normalizeEmailDocuments}
            onChange={(e) =>
              onValueChange({
                ...value,
                dataNormalization: {
                  ...(value.dataNormalization || {}),
                  normalizeEmailDocuments: e.target.checked,
                },
              })
            }
          >
            <WiStars
              color="rgb(22, 119, 255)"
              size={26}
              style={{ marginTop: -20, marginBottom: -8 }}
            />
            {t('Extract and use email content')}
            <DCTooltip
              text={t(
                'Digicust can read information from emails. If you activate this, Digicust will extract and use information from the email content.',
              )}
            />
          </Checkbox>
          {isAdmin && (
            <div className="hoverable">
              <LockOutlined style={{ marginRight: '5px' }} />{' '}
              {t('Annotation Remarks')}
              <DCTooltip
                text={t(
                  'Provide additional information in natural language on how data shall be annotated for training.',
                )}
              />
              <TextArea
                size="small"
                value={value?.dataExtraction?.annotationRemarks}
                onChange={(e) =>
                  onValueChange({
                    ...value,
                    dataExtraction: {
                      ...(value.dataExtraction || {}),
                      annotationRemarks: e.target.value,
                    },
                  })
                }
              />
            </div>
          )}
          {(value?.dataExtraction?.engine || 'default') !== 'default' && (
            <>
              <div className="hoverable">
                {t('Specification')}
                <DCTooltip
                  text={t(
                    'Specify a specific model that you want to use. If you leave this empty, the latest model will be used.',
                  )}
                />
                <Input
                  size="small"
                  value={value?.dataExtraction?.engineSpecification}
                  onChange={(e) =>
                    onValueChange({
                      ...value,
                      dataExtraction: {
                        ...(value.dataExtraction || {}),
                        engineSpecification: e.target.value,
                      },
                    })
                  }
                />
              </div>

              <div className="hoverable">
                {t('Prompt remarks')}
                <DCTooltip
                  text={t(
                    'Provide additional information in natural language on how data shall be extracted from documents.',
                  )}
                />
                <TextArea
                  size="small"
                  value={value?.dataExtraction?.promptRemarks}
                  onChange={(e) =>
                    onValueChange({
                      ...value,
                      dataExtraction: {
                        ...(value.dataExtraction || {}),
                        promptRemarks: e.target.value,
                      },
                    })
                  }
                />
              </div>

              <Checkbox
                className="hoverable"
                checked={value?.dataExtraction?.requireHumanAnnotation}
                onChange={(e) =>
                  onValueChange({
                    ...value,
                    dataExtraction: {
                      ...(value.dataExtraction || {}),
                      requireHumanAnnotation: e.target.checked,
                    },
                  })
                }
              >
                {t('Require Human Annotation')}
                <DCTooltip
                  text={t(
                    'Specify whether to require human annotation. If not activated, then the engine will extract data and forward to the proceeding services automatically.',
                  )}
                />
              </Checkbox>
            </>
          )}
        </Form>
      )}
    </CardWrapper>
  );
};
