import { Form, Input } from 'antd';
import { LdvSftpConfig } from 'digicust_types';
import { useTranslation } from 'react-i18next';

export default function LDVSystemForm({
  config,
  changeData,
}: {
  config: LdvSftpConfig;
  changeData: (key: string, value: string | boolean) => void;
}) {
  const { t } = useTranslation();

  return (
    <Form>
      <Form.Item label={t('DNS Name')}>
        <Input
          placeholder={t('DNS Name')}
          value={config?.['sftp-dns-name']}
          onChange={(e) => changeData('sftp-dns-name', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('Port')}>
        <Input
          placeholder={t('Port')}
          value={config?.['sftp-port']}
          onChange={(e) => changeData('sftp-port', e.target.value)}
        />
      </Form.Item>

      <Form.Item label={t('Login name')}>
        <Input
          placeholder={t('Login name')}
          value={config?.['sftp-login-name']}
          onChange={(e) => changeData('sftp-login-name', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('Password')}>
        <Input.Password
          placeholder={t('Password')}
          value={config?.['sftp-password']}
          onChange={(e) => changeData('sftp-password', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('Inbound')}>
        <Input
          placeholder={t('Inbound')}
          value={config?.['sftp-inbound']}
          onChange={(e) => changeData('sftp-inbound', e.target.value)}
        />
      </Form.Item>
      {/* <Form.Item label="Outbound">
        <Input
          placeholder="Outbound"
          value={config?.['sftp-outbound']}
          onChange={(e) => changeData('sftp-outbound', e.target.value)}
        />
      </Form.Item> */}
    </Form>
  );
}
