import { Input, InputRef, Select, Space, Typography } from 'antd';
import { DomesticFreightCosts, ErrorModel, Money } from 'digicust_types';
import { BaseSelectRef } from 'rc-select';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useCurrencies } from '../../utils/useCodes';
import { RootState } from '../reduxToolkit/store';
import useScrollHandler from './useScrollHandler';

const AmountOfMoneyInput = ({
  keyValue,
  warningValue,
  value,
  onChange,
}: {
  keyValue?: string;
  warningValue?: string;
  value?: Money | DomesticFreightCosts;
  onChange: (value?: Money | DomesticFreightCosts) => void;
}) => {
  const currencies = useCurrencies();

  const [curValue, setCurValue] = useState<number>();
  useEffect(() => setCurValue(value?.value), [value?.value]);

  const StoreData = useSelector((state: RootState) => state?.caseSlices);
  const warnings = StoreData?.errorsWarnings;
  const errors = StoreData?.errors;
  const field = errors?.field || '';
  const warningData: ErrorModel = warnings?.find(
    (item) => item?.field === `aggregated.${keyValue}`,
  );
  const status = warningData?.severity === 'warning' ? 'warning' : '';

  const selectRef = useRef<BaseSelectRef>(null);
  const inputRef = useRef<InputRef>(null);

  const handleScroll = () => {
    if (selectRef.current) {
      selectRef.current.blur();
    }
  };

  useScrollHandler(handleScroll);

  useEffect(() => {
    if (inputRef?.current && field === `aggregated.${keyValue}`) {
      if (value?.value == warningValue) {
        inputRef?.current?.focus();
        inputRef?.current?.select();
      }
    }
  }, [warningValue]);

  return (
    <Space.Compact style={{ width: '100%', borderRadius: 6 }}>
      <Input
        ref={inputRef}
        status={status}
        size="middle"
        style={{ width: '100%' }}
        placeholder="0"
        min={0}
        type="number"
        value={curValue}
        title={warningData?.message || ''}
        onChange={(e) => {
          setCurValue(Number(e.target.value));
        }}
        onBlur={() =>
          onChange({
            ...(value || {}),
            input: curValue?.toString(),
            value: Number(curValue),
            unit: value?.unit,
          })
        }
      />

      <Select
        ref={selectRef}
        status={status}
        popupMatchSelectWidth={100}
        style={{ width: '100px' }}
        showSearch
        placeholder="EUR"
        value={value?.unit}
        onChange={(e) =>
          onChange({
            ...(value || {}),
            unit: e,
          })
        }
      >
        {currencies
          ?.filter((item) => item?.code)
          ?.map((item, index) => (
            <Select.Option
              title={item.name}
              key={index}
              value={item.code}
              label={item.name}
            >
              <Typography.Text>{item.code}</Typography.Text>
            </Select.Option>
          ))}
      </Select>
    </Space.Compact>
  );
};

export default AmountOfMoneyInput;
