import { WechatFilled } from '@ant-design/icons';
import { Button, Drawer, Flex, Typography } from 'antd';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import ChatComponentImpl from '../Chat/ChatComponentImpl';
import { useTranslation } from 'react-i18next';

export default function TaricChatFloatingDrawer() {
  const { t } = useTranslation();

  const [showChat, setShowChat] = useState<boolean>(false);
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const customerId = pathSegments[1];
  const projectId = pathSegments[2];

  return (
    <>
      <Drawer
        open={showChat}
        onClose={() => setShowChat(false)}
        title={
          <Flex align="end" gap={5}>
            <Typography.Title level={3}>{t('Taric')}</Typography.Title>
            <Typography.Text>
              {t('Your personal customs expert')}
            </Typography.Text>
          </Flex>
        }
        footer={null}
        width={700}
        styles={{ body: { padding: '24px 24px 0 24px' } }}
      >
        {showChat && <ChatComponentImpl />}
      </Drawer>

      {location.pathname !== `/${customerId}/${projectId}` && (
        <Button
          onClick={() => setShowChat(true)}
          type="primary"
          style={{ position: 'fixed', bottom: -7, right: 120, zIndex: 5 }}
        >
          <WechatFilled /> Taric
        </Button>
      )}
    </>
  );
}
