import { LoadingOutlined } from '@ant-design/icons';
import {
  AutoComplete,
  Checkbox,
  Flex,
  Input,
  InputRef,
  Space,
  Typography,
} from 'antd';
import { CompanyModel, StakeholderModel } from 'digicust_types';
import { useEffect, useRef, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { EORINumberValidation } from '../../../Components/Display/EORINumberValidation';
import { VATNumberValidation } from '../../../Components/Display/VATNumberValidation';
import AddressSelect from '../../../Components/Inputs/AddressSelect';
import DebouncedInput from '../../../Components/Inputs/Common/DebouncedInput';
import { RootState } from '../../../Components/reduxToolkit/store';
import { StakeholderRepository } from '../../../utils/repositories/stakeholder.repository';
import { useAppDataContext } from '../../AppDataProvider';

const getStatus = (status?: string) => {
  switch (status) {
    case 'warning':
      return 'warning';
    case 'error':
      return 'error';
    default:
      return undefined;
  }
};

const CompanyDetailsInput = ({
  value,
  onChange,
  keyValue,
}: {
  value: CompanyModel;
  keyValue: string;
  onChange: (value: CompanyModel) => void;
}) => {
  const { t } = useTranslation();
  const { customerId, projectId } = useAppDataContext();

  const [stakeholders, setStakeholders] = useState<StakeholderModel[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const eoriRef = useRef<InputRef>(null);
  const vatRef = useRef<InputRef>(null);
  const nameRef = useRef<InputRef>(null);
  const phoneRef = useRef<InputRef>(null);
  const emailRef = useRef<InputRef>(null);

  const [eoriNumber, setEoriNumber] = useState<string>();
  const [vatNumber, setVatNumber] = useState<string>();
  const [contact, setContact] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [stakeholder, setStakeholder] = useState<string>();

  const StoreData = useSelector((state: RootState) => state?.caseSlices);
  const errors = StoreData?.errors;
  const field = errors?.field || '';
  const errorField = field.split('.')[1];

  const fetch = async (value?: string) => {
    setLoading(true);
    const response = await StakeholderRepository.getList(
      customerId,
      projectId,
      {
        search: [value || ''],
        sort: [{ field: '_ts', order: 'DESC' }],
        filter: [],
        pageLimit: 10000,
      },
    );
    setStakeholders(response.result);
    setLoading(false);
  };

  const filterStakeholders = (inputValue: any, option: any) => {
    return option.title.toLowerCase().startsWith(inputValue.toLowerCase());
  };

  const handleChange = (key: string, val?: string) => {
    onChange({
      ...(value || {}),
      [key]: {
        ...((value as any)?.[key] || ''),
        input: val,
        value: val,
      },
    });
  };

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    if (
      (eoriRef?.current ||
        vatRef?.current ||
        nameRef?.current ||
        phoneRef?.current ||
        emailRef?.current) &&
      // errors?.actual !== undefined &&
      // errors?.actual !== '' &&
      keyValue == errorField &&
      errors?.field !== `aggregated.${keyValue}.address`
    ) {
      if (
        value?.name?.value ||
        value?.EORI?.value ||
        value?.VAT?.value ||
        value?.contactPerson?.value ||
        value?.phoneNr?.value ||
        value?.email?.value == errors?.actual
      ) {
        if (eoriRef?.current?.input?.id === `eori${errorField}`) {
          eoriRef?.current?.focus();
          eoriRef?.current?.select();
        } else if (vatRef?.current?.input?.id === `vat${errorField}`) {
          vatRef?.current?.focus();
          vatRef?.current?.select();
        } else if (nameRef?.current?.input?.id === `name${errorField}`) {
          nameRef?.current?.focus();
          nameRef?.current?.select();
        } else if (phoneRef?.current?.input?.id === `phone${errorField}`) {
          phoneRef?.current?.focus();
          phoneRef?.current?.select();
        } else if (emailRef?.current?.input?.id === `email${errorField}`) {
          emailRef?.current?.focus();
          emailRef?.current?.select();
        }
      }
    }
  }, [errors]);

  useEffect(() => {
    const eori = value?.EORI?.value?.replace(/\s+/g, '');
    const vat = value?.VAT?.value?.replace(/\s+/g, '');
    setStakeholder(value?.name?.value);
    setEoriNumber(eori);
    setVatNumber(vat);
    setContact(value?.contactPerson?.value);
    setPhone(value?.phoneNr?.value);
    setEmail(value?.email?.value);
  }, [
    value?.name?.value,
    value?.EORI?.value,
    value?.VAT?.value,
    value?.contactPerson?.value,
    value?.phoneNr?.value,
    value?.email?.value,
  ]);

  return (
    <Space direction="vertical" size={10}>
      <AutoComplete
        style={{ width: '100%' }}
        options={stakeholders?.map((stakeholder, index) => {
          return {
            key: index,
            title: `${stakeholder?.name?.value}`,
            label: (
              <Space key={index}>
                <Typography.Text>
                  <ReactCountryFlag
                    svg
                    style={{ marginRight: '5px' }}
                    countryCode={stakeholder?.address?.alpha2Code as any}
                  />
                  {stakeholder?.name?.value}
                </Typography.Text>
                <Typography.Text
                  style={{ maxWidth: '250px' }}
                  ellipsis
                  type="secondary"
                >
                  {stakeholder?.address?.formattedAddress}
                </Typography.Text>
              </Space>
            ),
            value: stakeholder?.id,
          };
        })}
        suffixIcon={loading && <LoadingOutlined />}
        filterOption={filterStakeholders}
        onChange={(e) => {
          const stakeholder = stakeholders?.find(
            (company) => company?.id === e,
          );
          if (stakeholder) {
            onChange({
              ...(stakeholder || {}),
              stakeholderId: stakeholder?.id,
            });
          } else {
            setStakeholder(e);
          }
        }}
        onBlur={() => handleChange('name', stakeholder)}
        showSearch
        value={stakeholder}
        title={`${value?.name?.value || ''} ${value?.address?.value || ''}`}
        placeholder={t('Select Stakeholder')}
      />

      <AddressSelect
        keyValue={keyValue}
        value={value?.address}
        onValueChange={(address) =>
          onChange({
            ...(value || ''),
            address: { ...(address || {}), userEdited: true },
          })
        }
      />

      <Flex gap={5}>
        <Space direction="vertical" size={0} style={{ flex: 1 }}>
          <Typography.Text type="secondary" style={{ fontSize: '10px' }}>
            {t('Eori Number')}
          </Typography.Text>
          <Input
            value={eoriNumber}
            ref={eoriRef}
            id={`eori${errorField}`}
            placeholder={t('Eori Number')}
            title={
              value?.EORI?.errors
                ? value?.EORI?.errors[0]?.message
                : eoriNumber || ''
            }
            style={{ width: '100%' }}
            status={getStatus(
              value?.EORI?.errors && value?.EORI?.errors[0]?.severity,
            )}
            onChange={(e) => setEoriNumber(e.target.value)}
            onBlur={() => handleChange('EORI', eoriNumber)}
            prefix={<EORINumberValidation eori={value?.EORI} />}
          />
        </Space>

        <Space direction="vertical" size={0} style={{ flex: 1 }}>
          <Typography.Text type="secondary" style={{ fontSize: '10px' }}>
            {t('VAT number')}
          </Typography.Text>
          <Input
            value={vatNumber}
            ref={vatRef}
            placeholder={t('Vat Number')}
            id={`vat${errorField}`}
            style={{ width: '100%' }}
            title={
              value?.VAT?.errors
                ? value?.VAT?.errors[0]?.message
                : vatNumber || ''
            }
            status={getStatus(
              value?.VAT?.errors && value?.VAT?.errors[0]?.severity,
            )}
            onChange={(e) => setVatNumber(e.target.value)}
            onBlur={() => handleChange('VAT', vatNumber)}
            prefix={<VATNumberValidation vatNumber={value?.VAT} />}
          />
        </Space>
      </Flex>

      <Flex gap={5}>
        <Space direction="vertical" size={0} style={{ flex: 1 }}>
          <Typography.Text type="secondary" style={{ fontSize: '10px' }}>
            {t('Client Identifier')}
          </Typography.Text>

          <DebouncedInput
            value={value.clientIdentifier?.value}
            placeholder={t('Client Identifier')}
            onChange={(val) => handleChange('clientIdentifier', val)}
          />
        </Space>

        <Space direction="vertical" size={0} style={{ flex: 1 }}>
          <Typography.Text type="secondary" style={{ fontSize: '10px' }}>
            {t('External Identifier')}
          </Typography.Text>
          <DebouncedInput
            value={value.externalIdentifier?.value}
            placeholder={t('External Identifier')}
            onChange={(val) => handleChange('externalIdentifier', val)}
          />
        </Space>
      </Flex>

      <Input
        ref={nameRef}
        value={contact}
        placeholder={t('Contact Name')}
        id={`name${errorField}`}
        title={
          value?.contactPerson?.errors
            ? value?.contactPerson?.errors[0]?.message
            : value?.contactPerson?.value || ''
        }
        status={getStatus(
          value?.contactPerson?.errors &&
            value?.contactPerson?.errors[0]?.severity,
        )}
        onChange={(e) => setContact(e.target.value)}
        onBlur={() => handleChange('contactPerson', contact)}
      />

      <Flex gap={5}>
        <Space direction="vertical" size={0} style={{ flex: 1 }}>
          <Input
            ref={phoneRef}
            placeholder={t('Phone Number')}
            id={`phone${errorField}`}
            value={phone}
            title={
              value?.phoneNr?.errors
                ? value?.phoneNr?.errors[0]?.message
                : phone || ''
            }
            status={getStatus(
              value?.phoneNr?.errors && value?.phoneNr?.errors[0]?.severity,
            )}
            onChange={(e) => setPhone(e.target.value)}
            onBlur={() => handleChange('phoneNr', phone)}
          />
        </Space>
        <Space direction="vertical" size={0} style={{ flex: 1 }}>
          <Input
            ref={emailRef}
            value={email}
            placeholder={t('Email')}
            id={`email${errorField}`}
            title={
              value?.email?.errors
                ? value?.email?.errors[0]?.message
                : value?.email?.value || ''
            }
            status={getStatus(
              value?.email?.errors && value?.email?.errors[0]?.severity,
            )}
            onChange={(e) => setEmail(e.target.value)}
            onBlur={() => handleChange('email', email)}
          />
        </Space>
      </Flex>

      <Checkbox
        checked={value?.inputTaxDeduction?.value ?? true}
        onChange={(e) => {
          onChange({
            ...(value || {}),
            inputTaxDeduction: {
              ...(value?.inputTaxDeduction || ''),
              input: `${e.target.checked}`,
              value: e.target.checked,
            },
          });
        }}
      >
        {t('Input Tax')}
      </Checkbox>
    </Space>
  );
};

export default CompanyDetailsInput;
