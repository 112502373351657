import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Select, Space, Typography } from 'antd';
import { Incoterm, MeanOfTransportationMode, Rule } from 'digicust_types';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import DebouncedInput from '../../../../../Components/Inputs/Common/DebouncedInput';
import NumberInput from '../../../../../Components/Inputs/Common/NumberInput';
import CountrySelect from '../../../../../Components/Inputs/CountrySelect';
import CustomsOfficeSelect from '../../../../../Components/Inputs/CustomsOfficeSelect';
import IncotermSelect from '../../../../../Components/Inputs/IncotermSelect';
import MeanOfTransportationModeSelect from '../../../../../Components/Inputs/MeanOfTransportationModeSelect';
import PackageTypeSelect from '../../../../../Components/Inputs/PackageTypeSelect';
import PortSelect from '../../../../../Components/Inputs/PortSelect';
import StakeholderSelect from '../../../../../Components/Inputs/StakeholderSelect';
import { fieldList } from './FieldList';

const getConditionOptions = (conditionType: string) => {
  switch (conditionType) {
    case 'number':
      return [
        {
          value: 'equals',
          label: t('Equal To'),
        },
        {
          value: 'greaterThan',
          label: t('Greater Than'),
        },
        {
          value: 'smallerThan',
          label: t('Smaller'),
        },
        {
          value: 'isNot',
          label: t('Is Not'),
        },
      ];
    case 'array':
      return [
        {
          value: 'array_contains',
          label: t('Contains'),
        },
        {
          value: 'array_includes',
          label: t('Includes'),
        },
        {
          value: 'array_length_bigger',
          label: t('Count bigger than'),
        },
        {
          value: 'array_length_smaller',
          label: t('Count smaller than'),
        },
      ];
    case 'string':
      return [
        {
          value: 'contains',
          label: t('Contains'),
        },
        {
          value: 'startsWith',
          label: t('Starts With'),
        },
        {
          value: 'endsWith',
          label: t('Ends With'),
        },
        {
          value: 'equals',
          label: t('Equal To'),
        },
        {
          value: 'isNot',
          label: t('Is Not'),
        },
      ];
    default:
      return [
        {
          value: 'equals',
          label: t('Is'),
        },
        {
          value: 'isNot',
          label: t('Is Not'),
        },
      ];
  }
};

export default function ConditionBlock({
  value,
  onChange,
}: {
  value: Rule;
  onChange: (value: Rule) => void;
}) {
  const { t } = useTranslation();

  const updateCondition = (condition: any, index: number) => {
    if (typeof index === 'number') {
      const newValue = [...(value.conditions || [])];
      newValue[index] = condition;
      onChange({ ...value, conditions: newValue });
    }
  };

  return (
    <Card
      style={{
        borderLeft: '5px #1677ff solid',
        minWidth: 'min-content',
      }}
    >
      <Space direction="vertical">
        <Space>
          <Typography.Title level={5} style={{ margin: 0 }}>
            {t('Condition')}
          </Typography.Title>
          <Button
            icon={<PlusOutlined />}
            onClick={() =>
              onChange({
                ...(value || {}),
                conditions: [...(value?.conditions || []), {}],
              })
            }
          >
            {t('Add')}
          </Button>
        </Space>

        {value?.conditions?.map((condition, i) => {
          const conditionType =
            fieldList?.find((field) => field.value === condition.field)?.type ||
            '';
          return (
            <Space>
              <Select
                value={condition.field}
                popupMatchSelectWidth={400}
                showSearch
                style={{ width: '200px' }}
                placeholder="Field"
                optionFilterProp="children"
                onChange={(e) =>
                  updateCondition(
                    {
                      ...condition,
                      field: e,
                      operator: 'equals',
                    },
                    i,
                  )
                }
                filterOption={(input, option) =>
                  `${option?.search} ${option?.value}`
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  `${optionA?.search}`
                    .toLowerCase()
                    .localeCompare(`${optionB?.search}`.toLowerCase())
                }
                options={fieldList?.map((option) => ({
                  ...option,
                  search: option.label,
                  label: (
                    <Space direction="vertical" size={0}>
                      <Typography.Text>{option.label}</Typography.Text>
                      <Typography.Text ellipsis type="secondary">
                        {option.value}
                      </Typography.Text>
                    </Space>
                  ),
                }))}
              />

              {['number', 'string', 'array'].includes(conditionType) ? (
                <Select
                  value={condition.operator}
                  showSearch
                  onChange={(e) =>
                    updateCondition({ ...condition, operator: e }, i)
                  }
                  style={{ width: '120px' }}
                  placeholder="Operator"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? '')?.toString().includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                      ?.toString()
                      .toLowerCase()
                      .localeCompare(
                        (optionB?.label ?? '')?.toString().toLowerCase(),
                      )
                  }
                  options={getConditionOptions(conditionType)}
                />
              ) : (
                <Select
                  value={condition.operator}
                  showSearch
                  onChange={(e) =>
                    updateCondition({ ...condition, operator: e }, i)
                  }
                  style={{ width: '120px' }}
                  placeholder="Operator"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? '')?.toString().includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                      ?.toString()
                      .toLowerCase()
                      .localeCompare(
                        (optionB?.label ?? '')?.toString().toLowerCase(),
                      )
                  }
                  options={getConditionOptions(conditionType)}
                />
              )}

              {conditionType === 'number' ||
              (conditionType === 'array' &&
                ['array_length_smaller', 'array_length_bigger'].includes(
                  condition?.operator || '',
                )) ? (
                <div style={{ width: '200px' }}>
                  <NumberInput
                    value={Number(condition.value)}
                    placeholder="Value"
                    onChange={(value) =>
                      updateCondition({ ...condition, value }, i)
                    }
                  />
                </div>
              ) : conditionType === 'string' ? (
                <div style={{ width: '200px' }}>
                  <DebouncedInput
                    value={condition.value}
                    placeholder="Value"
                    onChange={(value) =>
                      updateCondition({ ...condition, value }, i)
                    }
                  />
                </div>
              ) : conditionType === 'array' ? (
                <div style={{ width: '200px' }}>
                  <DebouncedInput
                    value={condition.value}
                    placeholder="Value"
                    onChange={(value) =>
                      updateCondition({ ...condition, value }, i)
                    }
                  />
                </div>
              ) : conditionType === 'packageType' ? (
                <PackageTypeSelect
                  value={{ code: condition.value }}
                  onChange={(e) =>
                    updateCondition({ ...condition, value: e?.code }, i)
                  }
                />
              ) : conditionType === 'stakeholder' ? (
                <div style={{ width: '200px' }}>
                  <StakeholderSelect
                    value={{ id: condition.value || '' }}
                    onChange={(e) =>
                      updateCondition({ ...condition, value: e?.id || '' }, i)
                    }
                  />
                </div>
              ) : conditionType === 'customsOffice' ? (
                <div style={{ width: '200px' }}>
                  <CustomsOfficeSelect
                    value={{
                      value: condition.value || '',
                      code: { value: condition.value || '' },
                    }}
                    onChange={(e) =>
                      updateCondition(
                        { ...condition, value: e?.value || '' },
                        i,
                      )
                    }
                  />
                </div>
              ) : conditionType === 'incoterm' ? (
                <IncotermSelect
                  value={{ value: condition.value as Incoterm }}
                  onChange={(e) =>
                    updateCondition({ ...condition, value: e?.value || '' }, i)
                  }
                />
              ) : conditionType === 'meanOfTransportationMode' ? (
                <MeanOfTransportationModeSelect
                  value={{
                    value: condition.value as MeanOfTransportationMode,
                  }}
                  onChange={(e) =>
                    updateCondition({ ...condition, value: e?.value || '' }, i)
                  }
                />
              ) : conditionType === 'port' ? (
                <PortSelect
                  value={{ value: condition.value }}
                  onChange={(e) =>
                    updateCondition({ ...condition, value: e?.value || '' }, i)
                  }
                />
              ) : conditionType === 'country' ? (
                <CountrySelect
                  value={{ alpha2Code: condition?.value }}
                  onChange={(e) =>
                    updateCondition(
                      {
                        ...condition,
                        value: e?.alpha2Code || '',
                      },
                      i,
                    )
                  }
                />
              ) : conditionType === 'boolean' ? (
                <Select
                  style={{ width: '200px' }}
                  value={condition?.value}
                  options={[
                    { value: true, label: 'Yes' },
                    { value: false, label: 'No' },
                  ]}
                  onChange={(e) =>
                    updateCondition(
                      {
                        ...condition,
                        value: e || false,
                      },
                      i,
                    )
                  }
                />
              ) : null}

              <Button
                onClick={() => {
                  const updatedConditions =
                    value?.conditions?.filter(
                      (condition, index) => index !== i,
                    ) || [];
                  onChange({
                    ...value,
                    conditions: updatedConditions,
                  });
                }}
                icon={<DeleteOutlined />}
              />
            </Space>
          );
        })}
      </Space>
    </Card>
  );
}
