import { Button, Input, Typography } from 'antd';
import { Translatable } from 'digicust_types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const GoodsDescriptionInput = ({
  value,
  onChange = () => null,
  itemId,
  onCopy = () => null,
  isCopied,
}: {
  value?: Translatable;
  onChange: (val: Translatable) => void;
  itemId?: string;
  onCopy: () => void;
  isCopied: boolean;
}) => {
  const { t } = useTranslation();
  const [curValue, setCurValue] = useState<string>('');

  useEffect(() => {
    if (value?.value) setCurValue(value?.value);
  }, [value?.value]);

  const handleClick = (e: React.MouseEvent) => {
    if (e.ctrlKey || e.metaKey) {
      onCopy();
    }
  };

  return (
    <Input
      // Needed for focusing from warning
      id={`customs-description-${itemId}`}
      placeholder={t('Good Description')}
      value={curValue}
      onChange={(e) => {
        setCurValue(e.target.value);
      }}
      onBlur={() => {
        onChange({
          ...value,
          input: curValue,
          value: curValue,
          userEdited: true,
        });
      }}
      onClick={(e) => handleClick(e)}
      status={!value?.userEdited ? 'warning' : ''}
      title={curValue}
      style={{
        width: '100%',
        paddingRight: 5,
        outline: isCopied ? '2px solid blue' : '',
      }}
      suffix={
        <Button
          size="small"
          type="link"
          tabIndex={-1}
          icon={<Typography.Text copyable={{ text: curValue }} tabIndex={-1} />}
        />
      }
    />
  );
};

export default GoodsDescriptionInput;
