import {
  BulbOutlined,
  LoadingOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Button, Flex, Space, Tooltip, Typography } from 'antd';
import { CustomsTariffNumber } from 'digicust_types';
import { useTranslation } from 'react-i18next';
import TariffNumberIcon from '../../../Components/Display/TariffNumberIcon';
import { SeverityIcon } from '../../../Components/Inputs/CustomValidationMessagesComponent ';
import { TariffSuggestionTagBox } from '../../../Components/TariffClassification';
import { formatTariffNumber } from '../../../utils/helpers/formatTariffNumber';
import { TariffNumber, useTariffNumbers } from '../../../utils/useCodes';

const padNumber = (num: string, length: number) =>
  num.toString().padEnd(length, '0');

function getTariffNumberMatch(ctnValue: string, tariffNumbers: TariffNumber[]) {
  const findTariffNumber = (value: string) => {
    return tariffNumbers?.find((number) => number?.code === value);
  };

  let tariffNumber = findTariffNumber(ctnValue);
  if (!tariffNumber) {
    const lengths = [11, 8, 6, 4];
    for (const length of lengths) {
      if (ctnValue.length < length) {
        tariffNumber = findTariffNumber(padNumber(ctnValue, length));
        if (tariffNumber) break;
      }
    }
  }

  return tariffNumber;
}

function getTariffDescMatch(ctnValue: string, tariffNumbers: TariffNumber[]) {
  const findDescription = (code: string) => {
    return tariffNumbers.find((number) => number?.code === code)?.description;
  };

  let tariffNumberDescription = findDescription(ctnValue);
  if (!tariffNumberDescription) {
    const lengths = [11, 8, 6, 4];
    for (const length of lengths) {
      if (ctnValue.length < length) {
        tariffNumberDescription = findDescription(padNumber(ctnValue, length));
        if (tariffNumberDescription) break;
      }
    }
  }

  return tariffNumberDescription;
}

export function TariffNumberDescription({
  tariffNumber,
}: {
  tariffNumber: string;
}) {
  const tariffNumbers = useTariffNumbers();

  const tariffNumberMatch = getTariffNumberMatch(tariffNumber, tariffNumbers);
  const tariffDescription = getTariffDescMatch(tariffNumber, tariffNumbers);

  if (!tariffDescription || tariffDescription === 'andere') return null;

  return (
    <Flex align="center" gap={5}>
      {tariffNumberMatch && <SeverityIcon severity={'success'} />}

      <Typography.Paragraph
        ellipsis={{ rows: 1, expandable: true }}
        title={tariffDescription}
        type="secondary"
        style={{ width: '100%', whiteSpace: 'break-spaces' }}
      >
        {tariffDescription}
      </Typography.Paragraph>
    </Flex>
  );
}

export default function TableTariffNumberComponent({
  customsTariffNumber,
  onChange,
  openClassify,
}: {
  customsTariffNumber?: CustomsTariffNumber;
  onChange: (customsTariffNumber: CustomsTariffNumber) => void;
  openClassify: () => void;
}) {
  const ctnValue = customsTariffNumber?.value || '';
  const suggestion = customsTariffNumber?.suggestions?.[0];

  const isSuggestionPresent =
    suggestion?.tariffNumber?.length && !suggestion?.rejected;

  const onAccept = () => {
    const tariff = suggestion?.tariffNumber;
    const newSuggestions = customsTariffNumber?.suggestions?.slice(1);
    onChange({
      ...(customsTariffNumber || {}),
      suggestions: newSuggestions,
      hsCode: { value: tariff, input: tariff },
      value: tariff,
      input: tariff,
      taric: { processedByTaric: true },
    });
  };

  const onCancel = () => {
    const newSuggestions = [...(customsTariffNumber?.suggestions || [])];
    if (newSuggestions[0])
      newSuggestions[0] = { ...newSuggestions[0], rejected: true };
    onChange({
      ...(customsTariffNumber || {}),
      suggestions: newSuggestions,
    });
  };

  const { t } = useTranslation();

  const renderTariffNumber = () => (
    <Space size={0} style={{ width: '100%' }} direction="vertical">
      <Flex justify="space-between" align="center">
        <Flex align="center" gap={5}>
          <TariffNumberIcon tariffNumber={ctnValue} />

          <Typography.Text copyable={{ text: ctnValue }}>
            {formatTariffNumber(ctnValue)}
          </Typography.Text>

          {customsTariffNumber?.taric?.toBeProcessed && (
            <Tooltip title={t('Taric classification in progress')}>
              <LoadingOutlined style={{ fontSize: 12 }} />
            </Tooltip>
          )}

          {customsTariffNumber?.taric?.processedByTaric && (
            <Tooltip title={t('Processed by taric.')}>
              <BulbOutlined style={{ fontSize: 12 }} />
            </Tooltip>
          )}

          {customsTariffNumber?.taric?.isError && (
            <Tooltip
              title={
                customsTariffNumber?.taric?.error || t('Something went wrong.')
              }
            >
              <WarningOutlined style={{ fontSize: 12, color: 'red' }} />
            </Tooltip>
          )}
        </Flex>

        {renderSuggestion(true)}
      </Flex>
      <TariffNumberDescription tariffNumber={ctnValue} />
    </Space>
  );

  const renderSuggestion = (inline = false) =>
    isSuggestionPresent ? (
      <TariffSuggestionTagBox
        suggestion={suggestion!}
        inline={inline}
        onAccept={onAccept}
        onCancel={onCancel}
      />
    ) : null;

  const renderClassifyButton = () => (
    <Flex gap={5}>
      <Button onClick={openClassify} type="dashed">
        {t('Classify')}
      </Button>
      {customsTariffNumber?.taric?.toBeProcessed && (
        <Tooltip title={t('Taric classification in progress')}>
          <LoadingOutlined style={{ fontSize: 12 }} />
        </Tooltip>
      )}
    </Flex>
  );

  return ctnValue
    ? renderTariffNumber()
    : isSuggestionPresent
      ? renderSuggestion()
      : renderClassifyButton();
}
