import axios from 'axios';
import {
  BTISuggestion,
  TariffNumberSuggestion,
  TariffSystem,
} from 'digicust_types';
import { GENERIC_URL } from '../repositories/constants';

export class TariffClassificationService {
  public static classify = async ({
    customerId,
    projectId,
    description,
    remark,
    tariffSystem,
    barCode,
  }: {
    customerId: string;
    projectId: string;
    description: string;
    tariffSystem?: TariffSystem;
    remark?: string;
    barCode?: string;
  }): Promise<TariffNumberSuggestion | null> => {
    const response = await axios.post(
      `${GENERIC_URL}/api/${customerId}/${projectId}/tariff-classification/classify_cn`,
      { description, remark, tariffSystem, barCode },
    );

    return response.data;
  };

  public static classifyMaterialIds = async ({
    customerId,
    projectId,
    materialIds,
    tariffSystem,
  }: {
    customerId: string;
    projectId: string;
    materialIds: { id: string; description?: string; barCode?: string }[];
    tariffSystem?: TariffSystem;
  }): Promise<string> => {
    try {
      await axios.post(
        `${GENERIC_URL}/api/${customerId}/${projectId}/tariff-classification/classify-material-data`,
        { materialIds, tariffSystem },
      );

      return 'material classification triggered';
    } catch (error) {
      console.log(error);
      throw new Error('Material classification failed');
    }
  };

  public static classifyLineItems = async ({
    customerId,
    projectId,
    lineItemIds,
    caseId,
    tariffSystem,
  }: {
    customerId: string;
    projectId: string;
    lineItemIds: { id: string; description?: string; barCode?: string }[];
    caseId: string;
    tariffSystem?: TariffSystem;
  }): Promise<string> => {
    try {
      await axios.post(
        `${GENERIC_URL}/api/${customerId}/${projectId}/tariff-classification/classify-line-items`,
        { lineItemIds, caseId, tariffSystem },
      );

      return 'Line item classification triggered';
    } catch (error) {
      console.log(error);
      throw new Error('Line item classification failed');
    }
  };

  public static stopClassification = async ({
    customerId,
    projectId,
    lineItemId,
    materialId,
  }: {
    customerId: string;
    projectId: string;
    lineItemId?: string;
    materialId?: string;
  }): Promise<string> => {
    try {
      await axios.post(
        `${GENERIC_URL}/api/${customerId}/${projectId}/tariff-classification/stop`,
        { lineItemId, materialId },
      );

      return 'Classification stopped.';
    } catch (error) {
      console.log(error);
      throw new Error('Stopping classification failed.');
    }
  };

  public static getBTISuggestions = async ({
    customerId,
    projectId,
    description,
  }: {
    customerId: string;
    projectId: string;
    description: string;
  }): Promise<BTISuggestion[]> => {
    try {
      const result: { data: BTISuggestion[] } = await axios.post(
        `${GENERIC_URL}/api/${customerId}/${projectId}/tariff-classification/bti-suggestions`,
        { description },
      );

      return result.data;
    } catch (error) {
      return [];
    }
  };
}
