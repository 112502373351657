import { Input, InputRef } from 'antd';
import React, { ReactNode, useEffect, useState } from 'react';

interface DebouncedInputProps {
  value?: string;
  onChange?: (e?: string) => void;
  placeholder?: string;
  id?: string;
  suffix?: ReactNode;
}

const DebouncedInput = React.forwardRef<InputRef, DebouncedInputProps>(
  ({ value, onChange, placeholder, id, suffix }, ref) => {
    const [curValue, setCurValue] = useState<string>();

    useEffect(() => {
      if (value) setCurValue(value);
    }, [value]);

    return (
      <Input
        suffix={suffix}
        ref={ref}
        id={id}
        placeholder={placeholder}
        title={curValue}
        value={curValue}
        onChange={(e) => setCurValue(e.target.value)}
        onBlur={() => onChange && onChange(curValue)}
      />
    );
  },
);

export default DebouncedInput;
