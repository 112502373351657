import { createSlice } from '@reduxjs/toolkit';

export interface CaseReduxState {
  errors: { field?: string; actual?: string };
  errorsWarnings: any[];
}

export const initialCaseState: CaseReduxState = {
  errors: {},
  errorsWarnings: [],
};

const caseSlice = createSlice({
  name: 'case',
  initialState: initialCaseState,
  reducers: {
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
    setErrorsWarnings: (state, action) => {
      state.errorsWarnings = action.payload;
    },
  },
});

export const { setErrors, setErrorsWarnings } = caseSlice.actions;
export default caseSlice.reducer;
