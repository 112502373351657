import { Input, Space } from 'antd';
import { Quantity } from 'digicust_types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UnitOfMesurementSelect } from './UnitOfMesurementSelect';

const QuantityInput = ({
  value,
  onChange,
}: {
  value?: Quantity | null;
  onChange: (value: Quantity | null) => void;
}) => {
  const { t } = useTranslation();

  const [curValue, setCurValue] = useState<number>();
  useEffect(() => setCurValue(value?.amount), [value?.amount]);

  return (
    <Space.Compact>
      <Input
        style={{ width: '100%' }}
        placeholder={t('Quantity')}
        type="number"
        value={curValue}
        onChange={(e) => setCurValue(Number(e.target.value))}
        onBlur={() =>
          onChange({
            ...(value || {}),
            input: curValue?.toString(),
            amount: Number(curValue),
          })
        }
      />
      <UnitOfMesurementSelect
        value={value?.uom}
        onChange={(uom) => {
          onChange({ ...(value || {}), uom });
        }}
      />
    </Space.Compact>
  );
};

export default QuantityInput;
