import {
  ArrowDownOutlined,
  ArrowRightOutlined,
  CopyOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { Button, Collapse, Space, Typography } from 'antd';

import { Rule } from 'digicust_types';
import { ReactNode, useState } from 'react';
import EditInformationModal from '../../../../Components/Inputs/EditInformationModal';
import AddToCatalogButton from './Catalog/AddToCatalogButton';
import ConditionBlock from './Components/ConditionBlock';
import ThenBlockCard from './Components/ThenBlockCard';
import styles from './Rule.module.css';

const RuleComponent = ({
  value,
  onCopy,
  onDelete,
  onValueChange,
  moveComponent,
}: {
  value: Rule;
  onValueChange?: (value: Rule) => void;
  onDelete?: () => void;
  onCopy?: () => void;
  moveComponent: ReactNode;
}) => {
  const [openStatus, setOpenStatus] = useState(false);

  return (
    <Collapse>
      <Collapse.Panel
        key="1"
        collapsible="icon"
        header={
          <Space>
            {moveComponent}
            <Typography.Text
              editable={
                onValueChange
                  ? {
                      autoSize: false,
                      onChange: (name) => {
                        onValueChange({ ...(value || {}), name });
                      },
                    }
                  : false
              }
            >
              {value?.name}
            </Typography.Text>
          </Space>
        }
        extra={
          <Space size="small">
            <AddToCatalogButton rule={value} />

            <Button
              size="small"
              type="link"
              onClick={onCopy}
              icon={<CopyOutlined />}
            />

            <Button
              size="small"
              type="link"
              onClick={onDelete}
              icon={<DeleteOutlined />}
            />
          </Space>
        }
      >
        <div className={styles.RuleContainer}>
          <ConditionBlock
            value={value}
            onChange={onValueChange ? onValueChange : () => null}
          />
          <ArrowRightOutlined className={styles.ArrowRight} />
          <ArrowDownOutlined className={styles.ArrowDown} />
          <ThenBlockCard
            value={value}
            onChange={onValueChange ? onValueChange : () => null}
            openInformationModal={() => setOpenStatus(true)}
          />
        </div>

        <EditInformationModal
          value={value}
          openStatus={openStatus}
          onCancel={() => setOpenStatus(false)}
          onChange={onValueChange ? onValueChange : () => null}
        />
      </Collapse.Panel>
    </Collapse>
  );
};

export default RuleComponent;
