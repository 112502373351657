import { Select, Typography } from 'antd';
import {
  BuyerSellerRelationship,
  BuyerSellerRelationshipMode,
} from 'digicust_types';
import { useTranslation } from 'react-i18next';

const BuyerSellerRelationSelect = ({
  value,
  onChange = () => null,
}: {
  value?: BuyerSellerRelationship;
  onChange?: (value?: BuyerSellerRelationship) => void;
}) => {
  const { t } = useTranslation();

  const options = [
    {
      label: t('Seller and buyer are not connected'),
      DE_A1760: '0',
      value: BuyerSellerRelationshipMode.NotConnected,
    },
    {
      label: t(
        'Seller and buyer are connected, but the connection has not influenced the price of the imported goods',
      ),
      DE_A1760: '1',
      value: BuyerSellerRelationshipMode.ConnectedNoInfluence,
    },
    {
      label: t(
        'Seller and buyer are connected, the connection has influenced the price of the imported goods',
      ),
      DE_A1760: '2',
      value: BuyerSellerRelationshipMode.ConnectedInfluence,
    },
  ];

  return (
    <Select
      style={{ width: '100%' }}
      showSearch
      placeholder={t('Select Relationship')}
      value={value?.value}
      onChange={(e) => {
        const option = options?.find((option) => option?.value === e);
        onChange(option);
      }}
    >
      {options?.map((item, key) => (
        <Select.Option title={item?.label} key={key} value={item?.value}>
          <Typography.Text ellipsis>
            <b>{item?.DE_A1760}</b> {item?.label}
          </Typography.Text>
        </Select.Option>
      ))}
    </Select>
  );
};

export default BuyerSellerRelationSelect;
