import {
  CheckOutlined,
  DeleteOutlined,
  ExclamationCircleTwoTone,
  InfoCircleTwoTone,
  WarningTwoTone,
} from '@ant-design/icons';
import { Button, Card, Col, Form, Input, List, Row, Select } from 'antd';
import { ValidationError } from 'digicust_types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const severityOptions = [
  { value: 'error', label: 'Error', color: 'red' },
  { value: 'warning', label: 'Warning', color: 'orange' },
  { value: 'info', label: 'Info', color: 'blue' },
];

interface CustomValidationMessagesComponentProps {
  value: ValidationError[];
  onValueChange: (value: ValidationError[]) => void;
}

export const SeverityIcon = (props: { severity?: string }) => {
  switch (props?.severity) {
    case 'error':
      return <ExclamationCircleTwoTone twoToneColor="red" />;
    case 'warning':
      return <WarningTwoTone twoToneColor="orange" />;
    case 'info':
      return <InfoCircleTwoTone twoToneColor="blue" />;
    case 'success':
      return <CheckOutlined style={{ color: 'green' }} />;
    default:
      return <ExclamationCircleTwoTone twoToneColor="red" />;
  }
};

const CustomValidationMessagesComponent: React.FC<
  CustomValidationMessagesComponentProps
> = ({ value, onValueChange }) => {
  const { t } = useTranslation();
  const [newMessage, setNewMessage] = useState('');
  const [newSeverity, setNewSeverity] = useState(severityOptions[0].value);

  const handleAddMessage = () => {
    onValueChange([
      ...value,
      {
        name: newMessage,
        message: newMessage,
        type: 'ValidationError',
        severity: newSeverity as 'error' | 'warning' | 'info',
      },
    ]);
    setNewMessage('');
    setNewSeverity(severityOptions[0].value);
  };

  const handleDeleteMessage = (index: number) => {
    const newValue = [...value];
    newValue.splice(index, 1);
    onValueChange(newValue);
  };

  return (
    <div>
      <Card title={t('Validation Messages')} style={{ margin: '20px' }}>
        <List
          itemLayout="horizontal"
          dataSource={value}
          renderItem={(item: ValidationError, index: number) => (
            <List.Item
              actions={[
                <Button
                  icon={<DeleteOutlined />}
                  onClick={() => handleDeleteMessage(index)}
                >
                  {t('Delete')}
                </Button>,
              ]}
            >
              <List.Item.Meta
                avatar={SeverityIcon({ severity: item.severity })}
                title={item.message}
              />
            </List.Item>
          )}
        />
      </Card>
      <Card
        title={t('Add New Message')}
        style={{ margin: '20px', padding: '20px' }}
      >
        <Form layout="vertical">
          <Row gutter={16}>
            <Col span={16}>
              <Form.Item label={t('New validation error message')}>
                <Input
                  value={newMessage}
                  onChange={(e) => setNewMessage(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={t('Severity')}>
                <Select
                  value={newSeverity}
                  onChange={(value) => setNewSeverity(value)}
                >
                  {severityOptions.map((severity, key) => (
                    <Option key={key} value={severity.value}>
                      {severity.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Button type="primary" onClick={handleAddMessage}>
            {t('Add Message')}
          </Button>
        </Form>
      </Card>
    </div>
  );
};

export default CustomValidationMessagesComponent;
