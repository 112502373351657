import { Input, InputRef, Select, Space, Typography } from 'antd';
import { ErrorModel, Weight, WeightUnit } from 'digicust_types';
import { BaseSelectRef } from 'rc-select';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useWeightUnits } from '../../utils/useCodes';
import { RootState } from '../reduxToolkit/store';
import useScrollHandler from './useScrollHandler';

const WeightInput = ({
  keyValue,
  warningValue,
  weight,
  onChange,
}: {
  keyValue?: string;
  warningValue?: string;
  weight?: Weight;
  onChange: (value?: Weight) => void;
}) => {
  const [value, setValue] = useState<number>();
  const weightUnits = useWeightUnits();

  const selectRef = useRef<BaseSelectRef>(null);
  const inputRef = useRef<InputRef>(null);

  const StoreData = useSelector((state: RootState) => state?.caseSlices);
  const warnings = StoreData?.errorsWarnings;
  const errors = StoreData?.errors;
  const field = errors?.field || '';
  const warningData: ErrorModel = warnings?.find(
    (item: any) => item?.field === `aggregated.${keyValue}`,
  );
  const status = warningData?.severity === 'warning' ? 'warning' : '';

  const handleScroll = () => {
    if (selectRef.current) {
      selectRef.current.blur();
    }
  };

  useScrollHandler(handleScroll);

  useEffect(() => {
    if (inputRef?.current && field === `aggregated.${keyValue}`) {
      if (weight?.value?.toFixed(2) == warningValue) {
        inputRef?.current?.focus();
        inputRef?.current?.select();
      }
    }
  }, [warningValue]);

  useEffect(() => {
    setValue(weight?.value);
  }, [weight?.value]);

  return (
    <Space.Compact style={{ width: '100%' }}>
      <Input
        ref={inputRef}
        style={{ width: '100%' }}
        status={status}
        placeholder="0"
        type="number"
        value={value?.toFixed(3)}
        title={warningData?.message || ''}
        onChange={(e) => setValue(Number(e.target.value))}
        onBlur={() =>
          onChange({
            ...(weight || {}),
            input: value?.toString(),
            value: Number(value),
            unit: weight?.unit || WeightUnit.kilogram,
          })
        }
      />
      <Select
        ref={selectRef}
        style={{ width: '100px' }}
        showSearch
        popupMatchSelectWidth={100}
        status={status}
        placeholder="kg"
        value={weight?.unit}
        onChange={(e) =>
          onChange({
            ...(weight || {}),
            unit: e,
          })
        }
      >
        {weightUnits
          ?.filter((item) => item?.code)
          ?.map((item, key) => (
            <Select.Option
              title={item.name}
              key={key}
              value={item.code}
              label={item.name}
            >
              <Space key={key}>
                <Typography.Text>{item.code}</Typography.Text>
              </Space>
            </Select.Option>
          ))}
      </Select>
    </Space.Compact>
  );
};

export default WeightInput;
