export const fieldList = [
  {
    label: 'Reference',
    value: 'reference',
    type: 'string',
  },
  {
    label: 'Email Sender',
    value: `documents[documentType="email"][0].from`,
    type: 'string',
  },
  {
    label: 'Email Body',
    value: `documents[documentType="email"][0].body`,
    type: 'string',
  },
  {
    label: 'MRN Number',
    value: 'aggregated.mrnNumber.value',
    type: 'string',
  },
  {
    label: 'Procedure',
    value: 'aggregated.procedure.value',
    type: 'string',
  },
  {
    label: 'Procedure Code',
    value: 'aggregated.procedure.procedureCodeCombination',
    type: 'string',
  },
  {
    label: 'Representation',
    value: 'aggregated.constellationOfParticipants.value',
    type: 'string',
  },
  {
    label: 'Type of Business',
    value: 'aggregated.typeofBusiness.value',
    type: 'string',
  },
  {
    label: 'Trade Preference Valid',
    value: 'aggregated.tradePreference.valid',
    type: 'boolean',
  },
  {
    label: 'Trade Preference Text',
    value: 'aggregated.tradePreference.value',
    type: 'string',
  },
  {
    label: 'Total Value',
    value: 'aggregated.totalValue.value',
    type: 'number',
  },
  {
    label: 'Total Value Currency',
    value: 'aggregated.totalValue.unit',
    type: 'currency',
  },
  {
    label: 'Exit Customs Office',
    value: "aggregated.customsOffices[officeType = 'Exit'][0].code.value",
    type: 'customsOffice',
  },
  {
    label: 'Destination Customs Office',
    value:
      "aggregated.customsOffices[officeType = 'Destination'][0].code.value",
    type: 'customsOffice',
  },
  {
    label: 'Export Customs Office',
    value: "aggregated.customsOffices[officeType = 'Export'][0].code.value",
    type: 'customsOffice',
  },
  {
    label: 'Declaration Customs Office',
    value:
      "aggregated.customsOffices[officeType = 'Declaration'][0].code.value",
    type: 'customsOffice',
  },
  {
    label: 'Entry Customs Office',
    value: "aggregated.customsOffices[officeType = 'Entry'][0].code.value",
    type: 'customsOffice',
  },
  {
    label: 'Import Customs Office',
    value: "aggregated.customsOffices[officeType = 'Import'][0].code.value",
    type: 'customsOffice',
  },
  {
    label: 'Departure Customs Office',
    value: "aggregated.customsOffices[officeType = 'Departure'][0].code.value",
    type: 'customsOffice',
  },
  {
    label: 'Departure Customs Office',
    value: "aggregated.customsOffices[officeType = 'Departure'][0].code.value",
    type: 'customsOffice',
  },
  {
    label: 'Departure Address',
    value: 'aggregated.fromAddress.formattedAddress',
    type: 'string',
  },
  {
    label: 'Departure Country',
    value: 'aggregated.fromAddress.alpha2Code',
    type: 'country',
  },
  {
    label: 'Client Identifier',
    value:
      'aggregated.importer.clientIdentifier.value ? aggregated.importer.clientIdentifier.value : (aggregated.exporter.clientIdentifier.value ? aggregated.exporter.clientIdentifier.value : "00101"',
    type: 'string',
  },
  {
    label: 'Destination Address',
    value: 'aggregated.toAddress.formattedAddress',
    type: 'string',
  },
  {
    label: 'Destination Country',
    value: 'aggregated.toAddress.alpha2Code',
    type: 'country',
  },
  {
    label: 'Place of Loading',
    value: 'aggregated.placeOfLoading.formattedAddress',
    type: 'string',
  },
  {
    label: 'Point of Entry',
    value: 'aggregated.pointOfEntry.formattedAddress',
    type: 'string',
  },
  {
    label: 'Point of Exit',
    value: 'aggregated.pointOfExit.formattedAddress',
    type: 'string',
  },
  {
    label: 'Incoterm',
    value: 'aggregated.incoterm.value',
    type: 'incoterm',
  },
  {
    label: 'Incoterm Place',
    value: 'aggregated.placeIncoterm.formattedAddress',
    type: 'string',
  },
  {
    label: 'Port of Import',
    value: 'aggregated.portOfImport.value',
    type: 'port',
  },
  {
    label: 'Port of Export',
    value: 'aggregated.portOfExport.value',
    type: 'port',
  },
  {
    label: 'Freight Costs',
    value: 'aggregated.freightCosts.value',
    type: 'number',
  },
  {
    label: 'Domestic Costs',
    value: 'aggregated.domesticFreightCosts.value',
    type: 'number',
  },
  {
    label: 'Total Gross Weight',
    value: 'aggregated.weight.value',
    type: 'number',
  },
  {
    label: 'Total Net Weight',
    value: 'aggregated.netWeight.value',
    type: 'number',
  },
  {
    label: 'Number of Packages',
    value: 'aggregated.numberOfShipments.value',
    type: 'number',
  },
  {
    label: 'Package Type',
    value: '(aggregated.packages.type.code)[0]',
    type: 'packageType',
  },
  {
    label: 'Mean of Transportation at Border (Shipping Type)',
    value: `aggregated.meansOfTransportation[type="Border"].value`,
    type: 'meanOfTransportationMode',
  },
  {
    label: 'Mean of Transportation at Border',
    value: `aggregated.meansOfTransportation[type="Border"].licensePlate`,
    type: 'string',
  },
  {
    label: 'Line Item Count',
    value: `$count(aggregated.items)`,
    type: 'number',
  },
  {
    label: 'Line Item Value Sum',
    value: `$sum(aggregated.items.totalValue.value)`,
    type: 'number',
  },
  {
    label: 'Line Item Gross Weight Sum',
    value: `$sum(aggregated.items.totalGrossWeight.value)`,
    type: 'number',
  },
  {
    label: 'Line Item Net Weight Sum',
    value: `$sum(aggregated.items.totalNetWeight.value)`,
    type: 'number',
  },
  {
    label: 'Document Types',
    value: `documents.documentType`,
    type: 'array',
  },
  {
    label: 'Document References',
    value: `documents.reference`,
    type: 'array',
  },
  {
    label: 'Document Count',
    value: `$count(documents)`,
    type: 'number',
  },
  ...[
    {
      label: 'Shipper',
      value: 'aggregated.shipper',
    },
    {
      label: 'Waybill Shipper',
      value: `documents[documentType="waybill"][0].shipper`,
    },
    {
      label: 'Consignee',
      value: 'aggregated.consignee',
    },
    {
      label: 'Buyer',
      value: 'aggregated.buyer',
    },
    {
      label: 'Declarant',
      value: 'aggregated.declarant',
    },
    {
      label: 'Declarant Representative',
      value: 'aggregated.declarantRepresentative',
    },
  ]
    .map((stakeholder) => [
      {
        label: stakeholder.label,
        value: `${stakeholder.value}.stakeholderId`,
        type: 'stakeholder',
      },
      {
        label: `${stakeholder.label} Name`,
        value: `${stakeholder.value}.name.value`,
        type: 'string',
      },
      {
        label: `${stakeholder.label} Country`,
        value: `${stakeholder.value}.address.alpha2Code`,
        type: 'country',
      },
      {
        label: `${stakeholder.label} Address`,
        value: `${stakeholder.value}.address.formattedAddress`,
        type: 'string',
      },
      {
        label: `${stakeholder.label} VAT Number`,
        value: `${stakeholder.value}.VAT.value`,
        type: 'string',
      },
      {
        label: `${stakeholder.label} EORI Number`,
        value: `${stakeholder.value}.EORI.value`,
        type: 'string',
      },
    ])
    .flat(),
];
