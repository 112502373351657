import axios from 'axios';
import { CodeModel, TariffSystem } from 'digicust_types';
import { GENERIC_URL } from './constants';

// Increment this value to update the cache for all users
const CACHE_VERSION = 4;
const VERSION_KEY = 'cache-version';

export class CodesRepository {
  private static async openCache(cacheName: string): Promise<Cache> {
    return caches.open(cacheName);
  }

  private static async checkAndClearCache(
    cache: Cache,
    cacheName: string,
  ): Promise<Cache> {
    const currentVersion = await cache.match(VERSION_KEY);
    const currentVersionNumber = currentVersion
      ? parseInt(await currentVersion.text())
      : null;

    if (currentVersionNumber !== CACHE_VERSION) {
      await caches.delete(cacheName);
      const newCache = await caches.open(cacheName);
      await newCache.put(VERSION_KEY, new Response(CACHE_VERSION.toString()));
      return newCache;
    }

    return cache;
  }

  private static async fetchAndCacheData(
    cache: Cache,
    cacheName: string,
    url: string,
  ): Promise<CodeModel[]> {
    try {
      const response = await axios.get(url);

      const data = await response.data;
      await cache.put(cacheName, new Response(JSON.stringify(data)));
      return data as CodeModel[];
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  static getCodes = async (list: string): Promise<any[]> => {
    const cacheName = `codes-${list}`;

    const cache = await CodesRepository.openCache(cacheName);
    const refreshedCache = await CodesRepository.checkAndClearCache(
      cache,
      cacheName,
    );
    const cachedList = await refreshedCache.match(cacheName);

    if (cachedList) {
      return (await cachedList.json()) as CodeModel[];
    } else {
      return CodesRepository.fetchAndCacheData(
        refreshedCache,
        cacheName,
        `${GENERIC_URL}/api/codes/get-list/${list}`,
      );
    }
  };

  static getDocumentTypeCodes = async (): Promise<CodeModel[]> => {
    const cacheName = `documentTypeCodes`;

    const cache = await CodesRepository.openCache(cacheName);
    const refreshedCache = await CodesRepository.checkAndClearCache(
      cache,
      cacheName,
    );
    const cachedList = await refreshedCache.match(cacheName);

    if (cachedList) {
      return (await cachedList.json()) as CodeModel[];
    } else {
      return CodesRepository.fetchAndCacheData(
        refreshedCache,
        cacheName,
        `${GENERIC_URL}/api/lists/taric-certificates`,
      );
    }
  };

  public static getTaricTariffNumberTree = async (
    tariffSystem: TariffSystem,
  ): Promise<any> => {
    const cacheName = `tariff-numbers-tree-${tariffSystem}`;

    const cache = await CodesRepository.openCache(cacheName);
    const refreshedCache = await CodesRepository.checkAndClearCache(
      cache,
      cacheName,
    );

    const cachedList = await refreshedCache.match(cacheName);

    if (cachedList) {
      return await cachedList.json();
    } else {
      return CodesRepository.fetchAndCacheData(
        refreshedCache,
        cacheName,
        `${GENERIC_URL}/api/lists/tariff-tree/${tariffSystem}/import`,
      );
    }
  };

  public static getTariffNumbers = async (
    tariffSystem: TariffSystem,
  ): Promise<any> => {
    const cacheName = `tariffNumbers-${tariffSystem}`;

    const cache = await CodesRepository.openCache(cacheName);
    const refreshedCache = await CodesRepository.checkAndClearCache(
      cache,
      cacheName,
    );
    const cachedList = await refreshedCache.match(cacheName);

    if (cachedList) {
      return await cachedList.json();
    } else {
      return CodesRepository.fetchAndCacheData(
        refreshedCache,
        cacheName,
        `${GENERIC_URL}/api/lists/tariff-numbers/${tariffSystem}/import`,
      );
    }
  };

  public static getAdditionalTariffNumberCodes = async (): Promise<any> => {
    const cacheName = `additionalTariffNumberCodes`;

    const cache = await CodesRepository.openCache(cacheName);
    const refreshedCache = await CodesRepository.checkAndClearCache(
      cache,
      cacheName,
    );

    const cachedList = await refreshedCache.match(cacheName);

    if (cachedList) {
      return await cachedList.json();
    } else {
      return CodesRepository.fetchAndCacheData(
        refreshedCache,
        cacheName,
        '/AdditionalTariffCodesEnglish.json',
      );
    }
  };
}
