import { QuestionCircleTwoTone, SearchOutlined } from '@ant-design/icons';
import {
  Descriptions,
  Divider,
  Flex,
  Input,
  Popover,
  Space,
  Typography,
} from 'antd';
import { AggregatedCaseDataModel } from 'digicust_types';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../reduxToolkit/store';
import AddressSelect from './AddressSelect';
import AmountOfMoneyInput from './AmountOfMoneyInput';
import DebouncedInput from './Common/DebouncedInput';
import CostIntersectionSelect from './CostIntersectionSelect';
import CountrySelect from './CountrySelect';
import IncotermSelect from './IncotermSelect';
import PortSelect from './PortSelect';
import { CountrySelection } from './RouteSelect';
import WeightInput from './WeightInput';
const { Paragraph } = Typography;

type FieldType = {
  title: string;
  renderComponent: () => ReactNode;
  help?: ReactNode;
};

const FreightDocumentInput = ({
  caseData,
  updateItem,
}: {
  caseData: AggregatedCaseDataModel;
  updateItem: (caseData: AggregatedCaseDataModel) => void;
}) => {
  const { t } = useTranslation();
  const { errors } = useSelector((state: RootState) => state?.caseSlices);

  const fields: FieldType[] = [
    {
      title: t('Departure Address'),
      renderComponent: () => (
        <AddressSelect
          value={caseData.fromAddress}
          onValueChange={(fromAddress) => {
            updateItem({ ...caseData, fromAddress });
          }}
        />
      ),
    },
    {
      title: t('Route'),
      renderComponent: () => {
        return (
          <CountrySelection
            value={caseData?.route?.value || []}
            onRouteSelected={(e) => {
              updateItem({
                ...caseData,
                route: { input: JSON.stringify(e), value: e },
              });
            }}
            fromCountry={caseData?.route?.value?.[0] || ''}
            toCountry={caseData?.route?.value?.[1] || ''}
          />
        );
      },
    },
    {
      title: t('Destination Address'),
      renderComponent: () => (
        <AddressSelect
          value={caseData.toAddress}
          onValueChange={(toAddress) => {
            updateItem({ ...caseData, toAddress });
          }}
        />
      ),
    },
    {
      title: t('Point of Entry'),
      renderComponent: () => (
        <AddressSelect
          value={caseData.pointOfEntry}
          onValueChange={(pointOfEntry) => {
            updateItem({ ...caseData, pointOfEntry });
          }}
        />
      ),
    },
    {
      title: t('Point of Exit'),
      renderComponent: () => (
        <AddressSelect
          value={caseData.pointOfExit}
          onValueChange={(pointOfExit) => {
            updateItem({ ...caseData, pointOfExit });
          }}
        />
      ),
    },
    {
      title: t('Place of Loading'),
      renderComponent: () => (
        <AddressSelect
          value={caseData.placeOfLoading}
          onValueChange={(placeOfLoading) => {
            updateItem({ ...caseData, placeOfLoading });
          }}
        />
      ),
    },
    {
      title: t('Place of Loading Contact'),
      renderComponent: () => (
        <Space direction="vertical">
          <Space.Compact>
            <DebouncedInput
              placeholder={t('First name')}
              value={
                caseData.placeOfLoadingContact?.contactPerson?.firstName ||
                caseData.placeOfLoadingContact?.contactPerson?.value
              }
              onChange={(e) => {
                updateItem({
                  ...caseData,
                  placeOfLoadingContact: {
                    ...(caseData.placeOfLoadingContact || {}),
                    contactPerson: {
                      ...(caseData.placeOfLoadingContact?.contactPerson || {}),
                      firstName: e,
                      input: `${e} ${caseData.placeOfLoadingContact?.contactPerson?.lastName}`,
                      value: `${e} ${caseData.placeOfLoadingContact?.contactPerson?.lastName}`,
                    },
                  },
                });
              }}
            />
            <DebouncedInput
              placeholder={t('Last name')}
              value={caseData.placeOfLoadingContact?.contactPerson?.lastName}
              onChange={(e) => {
                updateItem({
                  ...caseData,
                  placeOfLoadingContact: {
                    ...(caseData.placeOfLoadingContact || {}),
                    contactPerson: {
                      ...(caseData.placeOfLoadingContact?.contactPerson || {}),
                      lastName: e,
                      input: `${caseData.placeOfLoadingContact?.contactPerson?.firstName} ${e}`,
                      value: `${caseData.placeOfLoadingContact?.contactPerson?.firstName} ${e}`,
                    },
                  },
                });
              }}
            />
          </Space.Compact>
          <DebouncedInput
            placeholder={t('Email')}
            value={caseData.placeOfLoadingContact?.email?.value}
            onChange={(e) => {
              updateItem({
                ...caseData,
                placeOfLoadingContact: {
                  ...(caseData.placeOfLoadingContact || {}),
                  email: { input: e, value: e },
                },
              });
            }}
          />
          <DebouncedInput
            placeholder={t('Phone Number')}
            value={caseData.placeOfLoadingContact?.phoneNr?.value}
            onChange={(e) => {
              updateItem({
                ...caseData,
                placeOfLoadingContact: {
                  ...(caseData.placeOfLoadingContact || {}),
                  phoneNr: { input: e, value: e },
                },
              });
            }}
          />
        </Space>
      ),
    },
    {
      title: t('Incoterm'),
      renderComponent: () => (
        <IncotermSelect
          value={caseData.incoterm}
          onChange={(incoterm) => {
            updateItem({ ...caseData, incoterm });
          }}
        />
      ),
    },
    {
      title: t('Incoterm Place'),
      renderComponent: () => (
        <Space.Compact style={{ width: '100%' }}>
          <span style={{ width: '50%' }}>
            <CountrySelect
              value={{ alpha2Code: caseData.placeIncoterm?.alpha2Code }}
              onChange={(placeIncoterm) =>
                updateItem({ ...caseData, placeIncoterm })
              }
            />
          </span>
          <span style={{ width: '50%' }}>
            <DebouncedInput
              placeholder={t('Locality')}
              aria-autocomplete="none"
              suffix={
                <a
                  tabIndex={-1}
                  target="popup"
                  rel="noreferrer"
                  href={`https://www.google.com/maps/search/?api=1&&query=${encodeURIComponent(
                    `${caseData.placeIncoterm?.alpha2Code} ${caseData.placeIncoterm?.locality}`,
                  )}`}
                >
                  <SearchOutlined />
                </a>
              }
              value={caseData.placeIncoterm?.locality}
              onChange={(locality) =>
                updateItem({
                  ...caseData,
                  placeIncoterm: { ...caseData.placeIncoterm, locality },
                })
              }
            />
          </span>
        </Space.Compact>
      ),
    },
    {
      title: t('Cost Intersection'),
      renderComponent: () => (
        <CostIntersectionSelect
          procedure={caseData.procedure?.value}
          value={caseData.costIntersection}
          onChange={(costIntersection) => {
            updateItem({ ...caseData, costIntersection });
          }}
        />
      ),
    },
    {
      title: t('Port of Export'),
      renderComponent: () => (
        <PortSelect
          value={caseData.portOfExport}
          onChange={(portOfExport) => {
            updateItem({ ...caseData, portOfExport });
          }}
        />
      ),
    },
    {
      title: t('Port of Import'),
      renderComponent: () => (
        <PortSelect
          value={caseData.portOfImport}
          onChange={(portOfImport) => {
            updateItem({ ...caseData, portOfImport });
          }}
        />
      ),
    },
    {
      title: t('Total Freight Costs'),
      renderComponent: () => (
        <AmountOfMoneyInput
          keyValue={'freightCosts'}
          warningValue={errors?.actual}
          value={caseData.freightCosts}
          onChange={(freightCosts) => {
            updateItem({ ...caseData, freightCosts });
          }}
        />
      ),
    },
    {
      title: t('Foreign Freight Costs'),
      renderComponent: () => (
        <AmountOfMoneyInput
          keyValue={'internationalFreightCosts'}
          warningValue={errors?.actual}
          value={caseData.internationalFreightCosts}
          onChange={(internationalFreightCosts) => {
            updateItem({ ...caseData, internationalFreightCosts });
          }}
        />
      ),
    },
    {
      title: t('Domestic Freight Costs'),
      help: (
        <Paragraph style={{ maxWidth: '400px' }}>
          <Descriptions column={1} size="small" bordered={true}>
            <Descriptions.Item label={t('From')}>
              {caseData.domesticFreightCosts?.reason?.from}
            </Descriptions.Item>
            <Descriptions.Item label={t('To')}>
              {caseData.domesticFreightCosts?.reason?.to}
            </Descriptions.Item>
            <Descriptions.Item label={t('Point of Entry')}>
              {caseData.domesticFreightCosts?.reason?.pointOfEntry}
            </Descriptions.Item>
            <Descriptions.Item label={t('Total Distance')}>
              {Math.round(
                parseInt(
                  (
                    caseData.domesticFreightCosts?.reason
                      ?.distanceInKilometers || 0
                  )?.toString(),
                  10,
                ),
              )}{' '}
              km
            </Descriptions.Item>
            <Descriptions.Item label={t('Foreign Distance')}>
              {Math.round(
                parseInt(
                  (
                    caseData.domesticFreightCosts?.reason
                      ?.foreignDistanceInKilometers || 0
                  )?.toString(),
                  10,
                ),
              )}{' '}
              km
            </Descriptions.Item>
            <Descriptions.Item label={t('Domestic Distance')}>
              {Math.round(
                parseInt(
                  (
                    caseData.domesticFreightCosts?.reason
                      ?.domesticDistanceInKilometers || 0
                  )?.toString(),
                  10,
                ),
              )}{' '}
              km (
              {Math.round(
                (caseData.domesticFreightCosts?.reason
                  ?.domesticFreightCostProportion || 0) * 100,
              )}
              % )
            </Descriptions.Item>
          </Descriptions>
        </Paragraph>
      ),
      renderComponent: () => (
        <AmountOfMoneyInput
          keyValue={'domesticFreightCosts'}
          warningValue={errors?.actual}
          value={caseData.domesticFreightCosts}
          onChange={(domesticFreightCosts) => {
            updateItem({ ...caseData, domesticFreightCosts });
          }}
        />
      ),
    },
    {
      title: t('Foreign Insurance Costs'),
      renderComponent: () => (
        <AmountOfMoneyInput
          keyValue={'insuranceCosts'}
          warningValue={errors?.actual}
          value={caseData.insuranceCosts}
          onChange={(insuranceCosts) => {
            updateItem({ ...caseData, insuranceCosts });
          }}
        />
      ),
    },
    {
      title: t('Domestic Insurance Costs'),
      renderComponent: () => (
        <AmountOfMoneyInput
          keyValue={'domesticInsuranceCosts'}
          warningValue={errors?.actual}
          value={caseData.domesticInsuranceCosts}
          onChange={(domesticInsuranceCosts) => {
            updateItem({ ...caseData, domesticInsuranceCosts });
          }}
        />
      ),
    },
    {
      title: t('Gross Weight'),
      renderComponent: () => (
        <WeightInput
          keyValue={'weight'}
          warningValue={errors?.actual}
          weight={caseData.weight}
          onChange={(weight) => {
            updateItem({ ...caseData, weight });
          }}
        />
      ),
    },
    {
      title: t('Net Weight'),
      renderComponent: () => (
        <WeightInput
          keyValue={'netWeight'}
          warningValue={errors?.actual}
          weight={caseData.netWeight}
          onChange={(netWeight) => {
            updateItem({ ...caseData, netWeight });
          }}
        />
      ),
    },
  ];

  return (
    <Space direction="vertical" style={{ width: '98%' }}>
      {fields.map((field) => (
        <div style={{ width: '100%' }}>
          {field.title === 'Total Freight Costs' ? <Divider /> : null}
          {field.title === 'Incoterm' ? <Divider /> : null}
          {field.title === 'Port of Export' ? <Divider /> : null}
          {field.title === 'Gross Weight' ? <Divider /> : null}
          <Flex justify="space-between">
            <Paragraph>
              {field.title}
              {field.help ? (
                <Popover
                  placement="right"
                  trigger="hover"
                  title={field.help ? field.title : null}
                  content={field.help ? field.help : null}
                >
                  <QuestionCircleTwoTone
                    style={{ marginLeft: '5px', cursor: 'pointer' }}
                  />
                </Popover>
              ) : null}
            </Paragraph>

            <div style={{ width: '50%' }}>{field.renderComponent()}</div>
          </Flex>
        </div>
      ))}
    </Space>
  );
};

export default FreightDocumentInput;
