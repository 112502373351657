import { message, Modal, UploadFile } from 'antd';
import { RcFile } from 'antd/lib/upload';
import axios from 'axios';
import {
  CaseModel,
  CaseSubmissionTransaction,
  DigicustDocumentType,
  DocumentStatus,
  FilterCondition,
  Rule,
  UserInput,
} from 'digicust_types';
import { config } from '../../config';
import { StatisticsFilters } from '../../Pages/Statistics/types';
import { GENERIC_URL } from './constants';

export class CaseRepository {
  public static uploadToNewCase = async (
    customerId: string,
    projectId: string,
    files: UploadFile[],
    reference?: string,
    executionStrategyId?: string,
    userInputData?: Rule | null,
    xmlDocuments?: UserInput[],
  ) => {
    try {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append('files[]', file.originFileObj as RcFile);
      });
      formData.append(
        'classifications',
        JSON.stringify(
          files.map((file: any) => ({
            fileName: file.name,
            documentType: file.documentType,
          })),
        ),
      );

      if (executionStrategyId) {
        formData.append('executionStrategyId', executionStrategyId);
      }
      if (reference) {
        formData.append('reference', reference);
      }

      // Adding user input from infomation and xml documents
      const documents: any[] = [];
      if (userInputData?.userInput?.annotatedAggregated) {
        const userInputDocument: UserInput = {
          documentType: DigicustDocumentType.UserInput,
          reference: 'User Input',
          documentStatus: DocumentStatus.Finished,
          priority: 'beforeNormalization',
          annotatedAggregated: userInputData?.userInput?.annotatedAggregated,
        };
        documents.push(userInputDocument);
      }
      if (xmlDocuments) documents.push(...xmlDocuments);
      if (documents.length > 0)
        formData.append('documents', JSON.stringify([...documents]));

      const { API_URL } = config;
      // const API_URL = 'http://localhost:3005';
      const response = await axios.post(
        `${API_URL}/upload/api/${customerId}/${projectId}/new`,
        formData,
      );

      return response.data;
    } catch (error) {
      console.log(error);
      message.error('Something went wrong.');
      return null;
    }
  };

  public static uploadToExistingCase = async (
    caseId: string,
    customerId: string,
    projectId: string,
    files: UploadFile[],
    onStatusChanged: (status: 'exception' | 'success') => void,
  ) => {
    try {
      const { API_URL } = config;

      const formData = new FormData();
      files.forEach((file) => {
        formData.append('files[]', file.originFileObj as RcFile);
      });
      formData.append(
        'classifications',
        JSON.stringify(
          files.map((file: any) => ({
            fileName: file.name,
            documentType: file.documentType,
          })),
        ),
      );

      const response = await axios.post(
        API_URL + `/upload/api/${customerId}/${projectId}/case/${caseId}`,
        formData,
      );
      onStatusChanged('success');
      return response.data;
    } catch (error) {
      console.log(error);
      onStatusChanged('exception');
      message.error('Something went wrong.');
      return null;
    }
  };

  public static getList = async (
    customerId?: string,
    projectId?: string,
    options?: {
      continuationToken?: string;
      filter?: {
        field: string;
        condition: FilterCondition;
        value: string | undefined | boolean | Date | number;
      }[];
      sort?: { field: string; order: 'ASC' | 'DESC' }[];
      properties?: string[];
      search?: string[];
      pageNumber?: number;
      pageLimit?: number | string;
    },
  ): Promise<any> => {
    try {
      const response = await axios.get(
        `${GENERIC_URL}/api/${customerId}/${projectId}/cases?options=${encodeURIComponent(
          JSON.stringify(options),
        )}`,
      );
      return response.data;
    } catch (error) {
      console.log(error);
      message.error('Something went wrong.');
      return null;
    }
  };

  public static get = async (
    customerId?: string,
    projectId?: string,
    id?: string,
    properties?: {
      skipReadLocks?: boolean;
      condensed?: boolean;
      limit?: number;
    },
  ): Promise<CaseModel | null> => {
    try {
      // Convert properties to string if it exists
      const params: any = {};
      if (properties !== undefined) {
        params.properties = encodeURIComponent(JSON.stringify(properties));
      }

      const response = await axios.get(
        `${GENERIC_URL}/api/${customerId}/${projectId}/cases/${id}`,
        { params },
      );
      return response.data;
    } catch (error) {
      console.log(error);
      message.error('Something went wrong.');
      return null;
    }
  };

  static getFilters = (filters?: StatisticsFilters): string => {
    if (!filters?.startTime && !filters?.stakeholderName) return '';

    const strings = [
      filters.startTime
        ? `startTime=${filters.startTime}&endTime=${filters.endTime}`
        : null,
      filters.stakeholderName
        ? `stakeholderName=${encodeURIComponent(
            filters?.stakeholderName,
          )}&stakeholderType=${filters.stakeholderType}`
        : null,
    ].filter((s: string | null) => !!s);

    return `?${strings.join('&')}`;
  };

  public static getStatistics = async (
    customerId?: string,
    projectId?: string,
    filters?: StatisticsFilters,
  ): Promise<any> => {
    try {
      const query = `${GENERIC_URL}/api/${customerId}/${projectId}/cases/statistics${this.getFilters(
        filters,
      )}`;

      const response = await axios.get(query);
      return response.data;
    } catch (error: any) {
      message.error(error?.response?.data || 'Something went wrong.');
      return null;
    }
  };

  public static getPivotData = async (
    customerId?: string,
    projectId?: string,
    filters?: StatisticsFilters,
  ): Promise<any> => {
    try {
      const query = `${GENERIC_URL}/api/${customerId}/${projectId}/cases/statistics/pivot${this.getFilters(
        filters,
      )}`;

      const response = await axios.get(query);
      return response.data;
    } catch (error: any) {
      message.error(error?.response?.data || 'Something went wrong.');
      return null;
    }
  };

  public static getCBAMData = async (
    customerId?: string,
    projectId?: string,
    filters?: StatisticsFilters,
  ): Promise<any> => {
    try {
      const query = `${GENERIC_URL}/api/${customerId}/${projectId}/cases/statistics/cbam${this.getFilters(
        filters,
      )}`;

      const response = await axios.get(query);
      return response.data;
    } catch (error: any) {
      message.error(error?.response?.data || 'Something went wrong.');
      return null;
    }
  };

  public static getATR = async (
    customerId?: string,
    projectId?: string,
    caseId?: string,
  ): Promise<any> => {
    try {
      const response = await axios.get(
        `${GENERIC_URL}/api/${customerId}/${projectId}/cases/${caseId}/atr`,
        { responseType: 'blob' },
      );
      return response.data;
    } catch (error: any) {
      message.error(error.response.data.message);
      return [];
    }
  };

  public static delete = async (
    customerId?: string,
    projectId?: string,
    id?: string,
  ): Promise<any> => {
    try {
      const response = await axios.delete(
        `${GENERIC_URL}/api/${customerId}/${projectId}/cases/${id}`,
      );

      return response.data;
    } catch (error) {
      console.log(error);
      message.error('Something went wrong.');
      return null;
    }
  };

  public static copy = async (
    customerId?: string,
    projectId?: string,
    id?: string,
    newExecutionStrategyId?: string,
    newData?: CaseModel,
    triggerProcessing?: boolean,
  ): Promise<CaseModel | null> => {
    try {
      const response = await axios.post(
        `${GENERIC_URL}/api/${customerId}/${projectId}/cases/${id}/copy`,
        {
          newExecutionStrategyId,
          newData,
          triggerProcessing,
        },
      );

      message.success('Case successfully copied.');
      return response.data;
    } catch (error) {
      console.error(error); // Using console.error to log errors
      return null;
    }
  };

  public static save = async (
    customerId?: string,
    projectId?: string,
    caseData?: CaseModel,
    soft?: boolean,
  ): Promise<CaseModel | null> => {
    try {
      if (caseData) {
        const newCaseData = {
          ...(caseData || {}),
          documents: soft
            ? caseData?.documents || []
            : [
                ...(caseData?.documents?.filter(
                  (doc) => doc?.documentType !== DigicustDocumentType.UserInput,
                ) || []),
                {
                  documentType: 'UserInput',
                  reference: 'User Input',
                  documentStatus: 'finished',
                  priority: 'replace',
                  annotatedAggregated: caseData?.aggregated,
                } as UserInput,
              ],
        };

        if (caseData?.id && customerId && projectId) {
          // update case information
          const caseResponse = await axios.patch(
            `${GENERIC_URL}/api/${customerId}/${projectId}/cases/${caseData?.id}`,
            newCaseData,
          );
          return caseResponse.data;
        }
        if (customerId && projectId) {
          // create new case
          const caseResponse = await axios.post(
            `${GENERIC_URL}/api/${customerId}/${projectId}/cases`,
            newCaseData,
          );
          return caseResponse.data;
        }
        return null;
      }
      return null;
    } catch (error) {
      console.log(error);
      message.error('Something went wrong.');
      return null;
    }
  };

  public static saveAndProcess = async (
    customerId?: string,
    projectId?: string,
    caseData?: CaseModel,
  ): Promise<CaseModel | null> => {
    const executeSaveAndProcessing = async (newCaseData: CaseModel) => {
      console.log('test0');
      if (newCaseData.flags) {
        newCaseData.flags = Object.entries(newCaseData.flags)
          ?.filter(
            (flag) =>
              !flag.includes('eventExecuted') ||
              !flag.includes('dakosySubmission') ||
              !flag.includes('aebSubmission') ||
              !flag.includes('dbhSubmission'),
          )
          .reduce((result, item) => {
            const key = Object.keys(item)[0];
            result[key] = item[key as any];
            return result;
          }, {} as any);
      }
      const res = await this.save(customerId, projectId, newCaseData);
      console.log('test1', customerId, projectId, caseData?.id || res?.id);
      await this.process(customerId, projectId, caseData?.id || res?.id);
      console.log('test2');

      return res;
    };
    console.log('test4');

    if (!caseData) return null;

    const submissionFlags = [
      'aebSubmission',
      'dbhSubmission',
      'dakosySubmission',
      'ldvSubmission',
      'dhfSubmission',
      'mercurioSubmission',
      'cargosoft',
    ];

    if (
      Object.keys(caseData?.flags || {}).some((key) =>
        submissionFlags.includes(key),
      )
    ) {
      return new Promise((resolve) => {
        Modal.confirm({
          title: 'Re-Submission?',
          content:
            'This case has already been submitted. Do you want to re-submit?',
          okText: 'Yes',
          cancelText: 'No, save and process, but do not submit',
          async onOk() {
            const newCaseData = { ...caseData };

            if (newCaseData.reference) {
              const parts = newCaseData.reference.split('_');
              const base = parts[0];
              const num = parts.length > 1 ? parseInt(parts[1], 10) : 0;
              newCaseData.reference = `${base}_${num + 1}`;
            }

            newCaseData.flags = Object.keys(newCaseData?.flags || {}).reduce(
              (newObj, key) => {
                if (!submissionFlags.includes(key)) {
                  newObj[key] = newCaseData?.flags?.[key];
                }
                return newObj;
              },
              {} as any,
            );
            const res = await executeSaveAndProcessing(newCaseData);
            resolve(res);
          },
          async onCancel() {
            const res = await executeSaveAndProcessing(caseData);
            resolve(res);
          },
        });
      });
    }

    return executeSaveAndProcessing(caseData);
  };

  public static process = async (
    customerId?: string,
    projectId?: string,
    caseId?: string,
  ): Promise<CaseModel | null> => {
    try {
      if (!(caseId && customerId && projectId)) {
        throw new Error('missing fields');
      }

      const caseResponse = await axios.get(
        `${GENERIC_URL}/api/${customerId}/${projectId}/cases/${caseId}/process`,
      );

      return caseResponse.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  public static requestInformation = async (
    customerId?: string,
    projectId?: string,
    caseId?: string,
    recipientEmail?: string,
    url?: string,
    prompt?: string,
    reference?: string,
  ): Promise<any> => {
    try {
      const response = await axios.post(
        `${GENERIC_URL}/api/${customerId}/${projectId}/cases/${caseId}/request-information`,
        {
          recipientEmail,
          url,
          prompt,
          reference,
        },
      );

      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  public static getCaseSubmissions = async (
    customerId?: string,
    projectId?: string,
    caseId?: string,
  ): Promise<CaseSubmissionTransaction[]> => {
    try {
      if (caseId && customerId && projectId) {
        const caseSubmissionsResponse = await axios.get(
          `${GENERIC_URL}/api/${customerId}/${projectId}/cases/${caseId}/submissions`,
        );

        return caseSubmissionsResponse.data;
      }
      return [];
    } catch (error) {
      console.log(error);
      return [];
    }
  };
}
