import { Flex, Spin } from 'antd';

export default function FullPageLoader() {
  return (
    <Flex
      justify="center"
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        paddingTop: '15%',
      }}
    >
      <Spin />
    </Flex>
  );
}
