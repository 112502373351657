import { Flex, Select, Typography } from 'antd';
import { AddressModel } from 'digicust_types';
import type { BaseSelectRef } from 'rc-select';
import { useEffect, useRef, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useCountryCodes } from '../../utils/useCodes';
import useScrollHandler from './useScrollHandler';
import { useTranslation } from 'react-i18next';

const CountrySelect = ({
  value,
  onChange = () => null,
  size,
}: {
  value?: AddressModel;
  onChange?: (value?: AddressModel) => void;
  size?: 'small' | 'large';
}) => {
  const { t } = useTranslation();
  const countries = useCountryCodes();
  const [filteredCountries, setFilteredCountries] = useState(countries);

  useEffect(() => {
    setFilteredCountries(countries);
  }, [countries]);

  const selectRef = useRef<BaseSelectRef>(null);

  const handleSearch = (searchText: string) => {
    if (!searchText) {
      setFilteredCountries(countries);
    } else {
      const searchLower = searchText.toLowerCase();
      const filtered = countries.filter(
        ({ country, alpha2Code }) =>
          country?.toLowerCase().startsWith(searchLower) ||
          alpha2Code?.toLowerCase() === searchLower,
      );
      setFilteredCountries(filtered);
    }
  };

  useScrollHandler(() => {
    selectRef.current?.blur();
  });

  return (
    <Select
      allowClear={true}
      ref={selectRef}
      showSearch
      popupMatchSelectWidth={270}
      placeholder={t('Select Country')}
      style={{ width: '100%' }}
      size={size || 'middle'}
      value={value?.alpha2Code}
      onSearch={handleSearch}
      onChange={(e) => onChange(countries.find((c) => c.alpha2Code === e))}
    >
      {filteredCountries.map((country, index) => (
        <Select.Option
          title={country.country}
          key={index}
          value={country.alpha2Code}
          label={country.country}
        >
          <Flex>
            <Flex justify="center" align="center">
              <ReactCountryFlag
                svg
                countryCode={country.alpha2Code || ''}
                style={{ marginRight: 8 }}
              />
            </Flex>
            <Typography.Text ellipsis>{country.country}</Typography.Text>
          </Flex>
        </Select.Option>
      ))}
    </Select>
  );
};

export default CountrySelect;
