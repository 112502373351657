import { Select, Space, Typography } from 'antd';
import { Port } from 'digicust_types';
import ReactCountryFlag from 'react-country-flag';
import { usePorts } from '../../utils/useCodes';

const PortSelect = ({
  value,
  onChange,
}: {
  value?: Port;
  onChange?: (value?: Port) => void;
}) => {
  const ports = usePorts();

  return (
    <Select
      style={{ width: '100%' }}
      showSearch
      placeholder="Port"
      value={value?.name}
      onChange={(e) =>
        onChange &&
        onChange({
          ...(ports.find((item) => item.name === e) || null),
          input: e,
        })
      }
    >
      {ports
        ?.filter((item) => item?.name)
        ?.map((item, key) => (
          <Select.Option
            title={item.name}
            key={key}
            value={item.name}
            label={item.name}
          >
            <Space>
              <Typography.Text strong className="alpha_flage">
                <ReactCountryFlag
                  svg
                  style={{ marginRight: '5px' }}
                  countryCode={item.address?.alpha2Code as any}
                />{' '}
                {item?.location}
              </Typography.Text>
              <Typography.Text>{item?.name}</Typography.Text>
              <Typography.Text
                style={{ maxWidth: '250px', display: 'flex' }}
                ellipsis
                type="secondary"
              >
                {item.address?.value}
              </Typography.Text>
            </Space>
          </Select.Option>
        ))}
    </Select>
  );
};

export default PortSelect;
