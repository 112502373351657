import { Select, Typography } from 'antd';
import { PaymentMethod } from 'digicust_types/lib/models/digicust/customs/payment-method.model';
import { useTranslation } from 'react-i18next';

const PaymentMethodSelect = ({
  value,
  onChange = () => null,
}: {
  value?: PaymentMethod;
  onChange?: (value?: PaymentMethod) => void;
}) => {
  const { t } = useTranslation();

  const options = [
    {
      value: 'A',
      label: t('Cash payment'),
      C0116: 'A',
    },
    {
      value: 'B',
      label: t('Credit card payment'),
      C0116: 'B',
    },
    {
      value: 'C',
      label: t('Payment by check'),
      C0116: 'C',
    },
    {
      value: 'D',
      label: t('Other (e.g., direct debit)'),
      C0116: 'D',
    },
    {
      value: 'H',
      label: t('Electronic payment'),
      C0116: 'H',
    },
    {
      value: 'Y',
      label: t('Account with carrier'),
      C0116: 'Y',
    },
    {
      value: 'Z',
      label: t('No prepayment'),
      C0116: 'Z',
    },
  ];

  return (
    <Select
      showSearch
      style={{ width: '100%' }}
      placeholder={t('Select Payment')}
      value={value?.value}
      onChange={(e) => {
        const option = options?.find((option) => option?.value === e);
        onChange(option);
      }}
    >
      {options?.map((item, key) => (
        <Select.Option title={item?.label} key={key} value={item?.value}>
          <Typography.Text ellipsis>
            <b>{item?.C0116}</b> {item?.label}
          </Typography.Text>
        </Select.Option>
      ))}
    </Select>
  );
};

export default PaymentMethodSelect;
