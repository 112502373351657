import { Select, Space, Typography } from 'antd';
import type { BaseSelectRef } from 'rc-select';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAdditionalTariffNumbers } from '../../utils/useCodes';
import { CTNSelectProps } from './types';

export default function AdditionalTariffCodeSelect({
  customsTariffNumber,
  onChange,
  onCopy = () => null,
  isAdditionalCodeCopied,
  dontShowStatus = false,
}: CTNSelectProps) {
  const { t } = useTranslation();

  const additionalTariffNumbers = useAdditionalTariffNumbers();
  const [additionalTariffCodes, setAdditionalTariffCodes] = useState<string[]>(
    [],
  );

  useEffect(() => {
    setAdditionalTariffCodes(
      customsTariffNumber?.additionalCodes?.map((c) => c.value || '') || [],
    );
  }, [customsTariffNumber]);

  const selectRef = useRef<BaseSelectRef>(null);

  const handleCopyClick = useCallback(
    (e: React.MouseEvent) => {
      if (e.ctrlKey || e.metaKey) {
        onCopy('additionalCodes');
        selectRef.current?.blur();
      }
    },
    [onCopy],
  );

  const handleChange = useCallback(
    (codes: string[]) => {
      setAdditionalTariffCodes(codes);
      onChange?.({
        ...(customsTariffNumber || {}),
        additionalCodes: codes.map((code) => ({ input: code, value: code })),
      });
    },
    [onChange, customsTariffNumber],
  );

  return (
    <Select
      ref={selectRef}
      id="additional-code"
      mode="multiple"
      status={
        !dontShowStatus && !customsTariffNumber?.userEdited ? 'warning' : ''
      }
      placeholder={t('Additional Codes')}
      showSearch
      style={{
        width: '50%',
        outline: isAdditionalCodeCopied ? '2px solid blue' : '',
        borderRadius: 6,
      }}
      popupMatchSelectWidth={435}
      placement="bottomRight"
      filterOption={(input, option) =>
        `${option?.value}`.toLowerCase().startsWith(input.toLowerCase())
      }
      onSearch={() => {
        if (selectRef.current) {
          selectRef.current.scrollTo({ index: 0 });
        }
      }}
      notFoundContent={null}
      value={additionalTariffCodes}
      onClick={handleCopyClick}
      onChange={handleChange}
      allowClear
    >
      {additionalTariffNumbers.map((item, index) => (
        <Select.Option
          title={item.description}
          key={index}
          value={item.code}
          label={item.code}
        >
          <Space key={index}>
            <Typography.Text>{item.code}</Typography.Text>
            <Typography.Text
              style={{ maxWidth: '520px' }}
              ellipsis
              type="secondary"
            >
              {item.description}
            </Typography.Text>
          </Space>
        </Select.Option>
      ))}
    </Select>
  );
}
