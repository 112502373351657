import {
  UilMetro,
  UilPlaneDeparture,
  UilShip,
  UilTruck,
} from '@iconscout/react-unicons';
import { Select, Typography } from 'antd';
import { MeanOfTransportation, MeanOfTransportationMode } from 'digicust_types';
import { useTranslation } from 'react-i18next';

const MeanOfTransportationModeSelect = ({
  value,
  onChange = () => null,
}: {
  value?: MeanOfTransportation;
  onChange?: (value?: MeanOfTransportation) => void;
}) => {
  const { t } = useTranslation();

  const options = [
    {
      label: t('Road'),
      value: MeanOfTransportationMode.road,
      icon: <UilTruck size="14" />,
      DE_A1990: '3',
      DE_A0108: '30',
      DE_A1140: '01',
    },
    {
      label: t('Air'),
      value: MeanOfTransportationMode.air,
      icon: <UilPlaneDeparture size="14" />,
      DE_A1990: '4',
      DE_A1140: '04',
      DE_A0108: '40',
    },
    {
      label: t('Maritime'),
      value: MeanOfTransportationMode.maritime,
      icon: <UilShip size="14" />,
      DE_A1990: '1',
      DE_A1140: '02',
      DE_A0108: '10',
    },
    {
      label: t('Inland Waterway'),
      value: MeanOfTransportationMode.inlandWaterway,
      icon: <UilShip size="14" />,
      DE_A1990: '8',
      DE_A1140: '02',
    },
    {
      label: t('Rail'),
      value: MeanOfTransportationMode.rail,
      icon: <UilMetro size="14" />,
      DE_A1990: '2',
      DE_A0108: '20',
      DE_A1140: '03',
    },
  ];

  return (
    <Select
      style={{ width: '100%' }}
      showSearch
      popupMatchSelectWidth={200}
      placeholder={t('Select Type')}
      value={value?.value}
      onChange={(e) => {
        const option = options?.find((option) => option?.value === e);
        onChange(option);
      }}
    >
      {options?.map((item, key) => (
        <Select.Option title={item?.DE_A1990} key={key} value={item?.value}>
          <Typography.Text ellipsis>
            <b>{item?.icon}</b> {item.label}
          </Typography.Text>
        </Select.Option>
      ))}
    </Select>
  );
};

export default MeanOfTransportationModeSelect;
