import { CloseOutlined, WarningOutlined } from '@ant-design/icons';
import {
  Button,
  Flex,
  Input,
  List,
  message,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import {
  ClassificationTypes,
  CompanyModel,
  TariffNumberSuggestion,
} from 'digicust_types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppDataContext } from '../../Pages/AppDataProvider';
import { TariffClassificationService } from '../../utils/services/tariff-classification.service';
import { TariffSuggestionTagBox } from './TariifSuggestionTagBox';
import { sortTariffSuggestions } from './helpers/sort-suggestions';

type TariffClassificationInputProps = {
  description?: string;
  onChange: ({
    tariffNumber,
    suggestions,
    toBeProcessed,
  }: {
    tariffNumber?: string;
    suggestions?: TariffNumberSuggestion[];
    toBeProcessed?: boolean;
  }) => void;
  shipper?: CompanyModel;
  _suggestions?: TariffNumberSuggestion[];
  lineItemId?: string;
  materialId?: string;
  barCode?: string;
  taricDetails: {
    processedByTaric?: boolean;
    toBeProcessed?: boolean;
    classificationType?: ClassificationTypes;
    isError?: boolean;
    error?: any;
  };
};

export const TariffClassificationInput = ({
  description,
  _suggestions = [],
  onChange = () => null,
  lineItemId,
  materialId,
  barCode,
  taricDetails,
}: TariffClassificationInputProps) => {
  const { customerId, projectId, projectDetails } = useAppDataContext();

  const { id } = useParams();
  const { t } = useTranslation();

  const [suggestions, setSuggestions] = useState<TariffNumberSuggestion[]>([]);
  useEffect(() => setSuggestions(_suggestions), [JSON.stringify(_suggestions)]);

  const [remark, setRemark] = useState('');

  const [suggestionsLoading, setSuggestionsLoading] = useState(false);
  useEffect(
    () => setSuggestionsLoading(!!taricDetails?.toBeProcessed),
    [!!taricDetails?.toBeProcessed],
  );

  const handleSubmit = async (description?: string) => {
    setSuggestionsLoading(true);

    try {
      let result = null;
      if (lineItemId) {
        result = await TariffClassificationService.classifyLineItems({
          customerId: customerId!,
          projectId: projectId!,
          lineItemIds: [{ id: lineItemId!, description, barCode }],
          caseId: id!,
          tariffSystem: projectDetails?.tariffNumberTreeSystem,
        });
      }

      if (materialId) {
        result = await TariffClassificationService.classifyMaterialIds({
          customerId: customerId!,
          projectId: projectId!,
          materialIds: [{ id: materialId!, description, barCode }],
          tariffSystem: projectDetails?.tariffNumberTreeSystem,
        });
      }

      onChange({ toBeProcessed: true });
      message.success(result);
    } catch (error: any) {
      message.error(error.response.data || 'Classification failed.');
    }
  };

  const stopClassification = async () => {
    try {
      const result = await TariffClassificationService.stopClassification({
        customerId: customerId!,
        projectId: projectId!,
        materialId,
        lineItemId,
      });
      onChange({ toBeProcessed: false });
      setSuggestionsLoading(false);
      message.success(result);
    } catch (error) {
      message.error('Stopping classification failed.');
    }
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Space size="small">
        <Typography.Title level={5}>
          {t('Tariff Classification')}
        </Typography.Title>
        {taricDetails?.isError && (
          <Tooltip title={taricDetails?.error || t('Something went wrong.')}>
            <WarningOutlined style={{ fontSize: 14, color: 'red' }} />
          </Tooltip>
        )}
      </Space>

      <Flex gap={10}>
        <Tooltip
          placement="bottom"
          title={t(
            'Provide additional information for the product (e.g. material)',
          )}
        >
          <Input
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
            placeholder={t('Remarks')}
          />
        </Tooltip>

        <Space.Compact>
          <Button
            type="primary"
            loading={suggestionsLoading}
            onClick={async () => handleSubmit(description)}
          >
            {t('Classify')}
          </Button>

          {suggestionsLoading && (
            <Button onClick={stopClassification} icon={<CloseOutlined />} />
          )}
        </Space.Compact>
      </Flex>

      {suggestionsLoading && (
        <Typography.Text type="secondary">
          {t('Classifying')} {description}.{' '}
          {t('This can take up to 60 seconds.')}
        </Typography.Text>
      )}

      {!!suggestions.length && (
        <List
          size="small"
          header={t('Suggestions')}
          style={{ width: '100%' }}
          itemLayout="horizontal"
          dataSource={suggestions}
          renderItem={(item, i) => (
            <List.Item>
              <TariffSuggestionTagBox
                suggestion={item}
                onAccept={() => {
                  const tariffNumber = suggestions[i].tariffNumber;
                  const newSuggestions = [...suggestions];
                  newSuggestions.splice(i, 1);

                  const sorted = sortTariffSuggestions(newSuggestions);
                  setSuggestions(sorted);
                  onChange({ tariffNumber, suggestions: sorted });
                }}
                onCancel={() => {
                  const newSuggestions = [...suggestions];
                  newSuggestions[i] = { ...newSuggestions[i], rejected: true };

                  const sorted = sortTariffSuggestions(newSuggestions);
                  setSuggestions(sorted);
                  onChange({ suggestions: sorted });
                }}
              />
            </List.Item>
          )}
        />
      )}
    </Space>
  );
};
