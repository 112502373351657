import { PlusOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import React, { useState } from 'react';
import AdditionalTariffCodeSelect from './additional-code-select';
import TariffSelectSuggestion from './custom-select-suggestion';
import TariffSelect from './tariff-select';
import { CTNSelectProps } from './types';

const CustomsTariffNumberSelect: React.FC<CTNSelectProps> = ({
  customsTariffNumber,
  onChange = () => null,
  itemId,
  dontShowStatus = false,
  showSuggestion = true,
  onCopy = () => null,
  isTariffNumberCopied,
  isAdditionalCodeCopied,
}) => {
  const [showAdditional, setShowAdditional] = useState(false);

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Space.Compact style={{ width: '100%' }}>
        <TariffSelect
          customsTariffNumber={customsTariffNumber}
          onChange={onChange}
          itemId={itemId}
          onCopy={onCopy}
          isTariffNumberCopied={isTariffNumberCopied}
          dontShowStatus={dontShowStatus}
          style={{
            width:
              showAdditional ||
              (customsTariffNumber?.additionalCodes?.length || 0) > 0
                ? '60%'
                : '100%',
          }}
        />

        {showAdditional ||
        (customsTariffNumber?.additionalCodes?.length || 0) > 0 ? (
          <AdditionalTariffCodeSelect
            customsTariffNumber={customsTariffNumber}
            onChange={onChange}
            isAdditionalCodeCopied={isAdditionalCodeCopied}
            itemId={itemId}
            onCopy={onCopy}
            dontShowStatus={dontShowStatus}
          />
        ) : (
          <Button
            tabIndex={-1}
            title="Add additional code"
            icon={<PlusOutlined />}
            onClick={() => setShowAdditional(true)}
          />
        )}
      </Space.Compact>

      {showSuggestion && (
        <TariffSelectSuggestion
          customsTariffNumber={customsTariffNumber}
          onChange={onChange}
        />
      )}
    </Space>
  );
};

export default CustomsTariffNumberSelect;
