import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input, Select } from 'antd';
import { DefermentAccount } from 'digicust_types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DGTable from '../../common/DGTable';

const DefermentAccounts = ({
  value,
  onValueChange,
}: {
  value: DefermentAccount[];
  onValueChange: (value: DefermentAccount[]) => void;
}) => {
  const { t } = useTranslation();

  const updateItem = (document: DefermentAccount, index: number) => {
    if (typeof index === 'number') {
      const newValue = [...value];
      newValue[index] = document;
      if (document?.value == index.toString()) {
        const upDatedValue = newValue.filter((item) => item !== document);
        onValueChange(upDatedValue);
      } else {
        onValueChange(newValue);
      }
    }
  };

  const A1190 = [
    { value: 'Import duties - 1 month', label: t('Import duties - 1 month') },
    {
      value: 'Import duties - 1 month - (without VAT)',
      label: t('Import duties - 1 month - (without VAT)'),
    },
    {
      value: 'EUSt - without security deposit',
      label: t('EUSt - without security deposit'),
    },
  ];

  const A1080 = [
    { value: 'Own tax debts', label: t('Own tax debts') },
    { value: 'Third-party tax debts', label: t('Third-party tax debts') },
  ];

  const oneNext: any = React.createRef();
  const twoNext: any = React.createRef();
  const threeNext: any = React.createRef();
  const fourNext: any = React.createRef();
  const fiveNext: any = React.createRef();
  const sixNExt: any = React.createRef();

  function handleKeyUpTwo(e: any) {
    e.which = e.which || e.keyCode;
    // If the key press is Enter
    if (e.which == 13) {
      switch (e.target.id) {
        case 'oneNext':
          twoNext.current.focus();
          break;
        case 'twoNext':
          threeNext.current.focus();
          break;
        case 'threeNext':
          fourNext.current.focus();
          break;
        case 'fourNext':
          fiveNext.current.focus();
          break;
        case 'fiveNext':
          sixNExt.current.focus();
          break;
        case 'sixNext':
          oneNext.current.focus();
          break;
        default:
          break;
      }
    }
  }

  return (
    <div className="stakeholderCustomsOfficeTable">
      <DGTable<DefermentAccount>
        cardBordered
        style={{ marginTop: '20px' }}
        headerTitle={t('Deferment Accounts')}
        scroll={{ x: '100%', y: 'calc(100vh - 200px)' }}
        dataSource={value || []}
        rightToolbar={
          <Button
            onClick={() => onValueChange([...value, {}])}
            key="button"
            icon={<PlusOutlined />}
          >
            {t('Add')}
          </Button>
        }
        columns={[
          {
            getValue: (items) => items?.accountNumber || '',
            title: t('Account Number'),
            key: 'accountNumber',
            fixed: 'left',
            width: '200px',
            render: (_, item, index) => (
              <Input
                key={index}
                value={item?.accountNumber}
                id="oneNext"
                ref={oneNext}
                onKeyUp={handleKeyUpTwo}
                size="middle"
                placeholder={t('Account Number')}
                onChange={(e) =>
                  updateItem(
                    {
                      ...(item || {}),
                      accountNumber: e.target.value || '',
                    },
                    index,
                  )
                }
              />
            ),
          },
          {
            getValue: (items) => items?.BIN || '',
            title: 'BIN',
            key: 'bin',
            width: '180px',
            render: (_, item, index) => (
              <Input
                key={index}
                value={item?.BIN}
                size="middle"
                placeholder="BIN"
                id="twoNext"
                ref={twoNext}
                onKeyUp={handleKeyUpTwo}
                type="text"
                onChange={(e) =>
                  updateItem(
                    {
                      ...(item || {}),
                      BIN: e.target.value || '',
                    },
                    index,
                  )
                }
              />
            ),
          },
          {
            getValue: (items) => items?.EORI || '',
            title: 'EORI',
            key: 'eori',
            width: '180px',
            render: (_, item, index) => (
              <Input
                key={index}
                value={item?.EORI}
                size="middle"
                placeholder="EORI"
                id="threeNext"
                ref={threeNext}
                onKeyUp={handleKeyUpTwo}
                type="text"
                onChange={(e) =>
                  updateItem(
                    {
                      ...(item || {}),
                      EORI: e.target.value || '',
                    },
                    index,
                  )
                }
              />
            ),
          },
          {
            getValue: (items) => items?.code || '',
            title: 'Code',
            key: 'code',
            width: '180px',
            render: (_, item, index) => (
              <Input
                key={index}
                value={item?.code}
                size="middle"
                id="fourNext"
                ref={fourNext}
                onKeyUp={handleKeyUpTwo}
                placeholder="Code"
                type="text"
                onChange={(e) =>
                  updateItem(
                    {
                      ...(item || {}),
                      code: e.target.value || '',
                    },
                    index,
                  )
                }
              />
            ),
          },
          {
            getValue: (items) => items?.A1190 || '',
            title: t('Debt Owner'),
            key: 'debtOwner',
            width: '250px',
            render: (_, item, index) => (
              <Select
                key={index}
                value={item?.A1190}
                style={{ width: '100%' }}
                size="middle"
                showSearch
                placeholder={t('Debt Owner')}
                id="fiveNext"
                ref={fiveNext}
                onKeyUp={handleKeyUpTwo}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? '').includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                options={A1190}
                onChange={(e) =>
                  updateItem(
                    {
                      ...(item || {}),
                      A1190: e || '',
                    },
                    index,
                  )
                }
              />
            ),
          },
          {
            getValue: (items) => items?.A1080 || '',
            title: t('Type of Deferment'),
            key: 'typeOfDeferment',
            width: '220px',
            render: (_, item, index) => (
              <Select
                key={index}
                style={{ width: '100%' }}
                value={item?.A1080}
                size="middle"
                showSearch
                placeholder={t('Type of Deferment')}
                id="sixNext"
                ref={sixNExt}
                onKeyUp={handleKeyUpTwo}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? '').includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                options={A1080}
                onChange={(e) =>
                  updateItem(
                    {
                      ...(item || {}),
                      A1080: e || '',
                    },
                    index,
                  )
                }
              />
            ),
          },
          {
            title: t('Actions'),
            key: 'action',
            width: '60px',
            render: (_, item, index) => (
              <Button
                onClick={async () => {
                  updateItem({ ...(item || {}), value: `${index}` }, index);
                }}
                icon={<DeleteOutlined />}
              />
            ),
          },
        ]}
      />
    </div>
  );
};

export default DefermentAccounts;
