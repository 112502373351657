import { Checkbox, Form, Input } from 'antd';
import { MercurioSftpConfig } from 'digicust_types';
import { useTranslation } from 'react-i18next';

export default function MercurioSystemForm({
  config,
  changeData,
}: {
  config: MercurioSftpConfig;
  changeData: (key: string, value: string | boolean) => void;
}) {
  const { t } = useTranslation();

  return (
    <Form>
      <Form.Item label={t('DNS Name')}>
        <Input
          placeholder={t('DNS Name')}
          value={config?.['sftp-dns-name']}
          onChange={(e) => changeData('sftp-dns-name', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('Port')}>
        <Input
          placeholder={t('Port')}
          value={config?.['sftp-port']}
          onChange={(e) => changeData('sftp-port', e.target.value)}
        />
      </Form.Item>

      <Form.Item label={t('Login name')}>
        <Input
          placeholder={t('Login name')}
          value={config?.['sftp-login-name']}
          onChange={(e) => changeData('sftp-login-name', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('Password')}>
        <Input.Password
          placeholder={t('Password')}
          value={config?.['sftp-password']}
          onChange={(e) => changeData('sftp-password', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('Inbound')}>
        <Input
          placeholder={t('Inbound')}
          value={config?.['sftp-inbound']}
          onChange={(e) => changeData('sftp-inbound', e.target.value)}
        />
      </Form.Item>
      {/* <Form.Item label="Outbound">
        <Input
          placeholder="Outbound"
          value={config?.['sftp-outbound']}
          onChange={(e) => changeData('sftp-outbound', e.target.value)}
        />
      </Form.Item> */}
      <Form.Item label={t('Client ID')}>
        <Input
          placeholder={t('Client ID')}
          value={config?.['clientId']}
          onChange={(e) => changeData('clientId', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('Interface Password')}>
        <Input
          placeholder={t('Interface Password')}
          value={config?.['interfacePassword']}
          onChange={(e) => changeData('interfacePassword', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('Template')}>
        <Input
          placeholder={t('Template')}
          value={config?.['template']}
          onChange={(e) => changeData('template', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('Aggregate Line Items')}>
        <Checkbox
          checked={config?.['aggregateItems']}
          onChange={(e) => changeData('aggregateItems', e.target.checked)}
        />
      </Form.Item>
    </Form>
  );
}
