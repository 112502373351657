import { Select, Space, Tag, Typography } from 'antd';
import { DigicustDocumentModel } from 'digicust_types';
import { BaseSelectRef } from 'rc-select';
import { useEffect, useRef, useState } from 'react';
import { useDocumentTypes } from '../../../utils/useCodes';
import ReferenceModal, { ReferenceModalData } from './reference-modal';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

/**
 * These types comes from rc-select node_module, not sure why this is not getting imported so i have just copied it here
 */
type CustomTagProps = {
  label: React.ReactNode;
  value: any;
  disabled: boolean;
  onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  closable: boolean;
};

const DocumentsTypeCode = ({
  value,
  onChange = () => null,
  onCopy = () => null,
  isCopied,
}: {
  value?: DigicustDocumentModel[];
  onChange?: (value: DigicustDocumentModel[]) => void;
  onCopy?: () => void;
  isCopied?: boolean;
}) => {
  const { t } = useTranslation();
  const documentTypeCodes = useDocumentTypes();

  const [objectValue, setObjectValue] = useState<DigicustDocumentModel[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setObjectValue(value || []);
  }, [value]);

  const selectRef = useRef<BaseSelectRef>(null);

  const handleClick = (e: React.MouseEvent) => {
    if (e.ctrlKey || e.metaKey) {
      onCopy();
      selectRef.current?.blur();
    }
  };

  const getItemLabel = (item?: DigicustDocumentModel) => {
    if (!item) return 'empty';

    return (
      <Space direction="vertical" size={0}>
        <Space size={5}>
          <Typography.Text style={{ fontSize: 12 }}>
            {item?.documentCode?.DE_I0200 ||
              item?.documentCode?.input ||
              item?.documentCode?.NC_49000 ||
              item?.documentCode?.Dokumentenarten ||
              item?.documentCode?.NC_44000 ||
              ''}
          </Typography.Text>
          <Typography.Text
            title={item?.reference}
            type="secondary"
            ellipsis
            style={{ maxWidth: 85, fontSize: 12 }}
          >
            {item?.reference}
          </Typography.Text>
        </Space>

        <Typography.Text type="secondary" style={{ fontSize: 12 }}>
          {item?.issueDate?.value
            ? dayjs(item?.issueDate?.value).format('DD-MM-YYYY')
            : ''}
        </Typography.Text>
      </Space>
    );
  };

  const tagRender = ({ value, onClose }: CustomTagProps) => {
    const handleClose = () => {
      onClose();
      const newDocuments: DigicustDocumentModel[] = [];
      objectValue?.forEach((values) => {
        if (value !== values.documentCode?.DE_I0200) {
          newDocuments.push(values);
        }
      });
      setObjectValue(newDocuments);
      onChange(newDocuments);
    };

    return (
      <Tag
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        closable
        onClose={handleClose}
        style={{ whiteSpace: 'nowrap', lineHeight: '12px' }}
      >
        {getItemLabel(
          objectValue.find((c) => c.documentCode?.DE_I0200 === value),
        )}
      </Tag>
    );
  };

  const [selectedCode, setSelectedCode] = useState('');

  const onSelect = (val?: string) => {
    if (val) setSelectedCode(val);
    selectRef.current?.blur();
    setIsModalOpen(true);
  };

  const handleOk = ({
    reference,
    issueDate,
    NC_43000,
    NC_44000,
    NC_49000,
    I0903,
  }: ReferenceModalData) => {
    const codeData = documentTypeCodes.find((c) => c.code === selectedCode);

    const newDocument: DigicustDocumentModel = {
      ...(issueDate
        ? { issueDate: { input: issueDate, value: issueDate } }
        : {}),
      reference,
      documentCode: {
        Dokumentenarten: selectedCode,
        DE_I0200: selectedCode,
        DE_I0200_Bereich: codeData?.segmentDE,
        NC_49000: NC_49000,
        NC_43000: NC_43000,
        NC_44000: NC_44000,
        DE_I0903: I0903,
      },
      userEdited: true,
    };

    setObjectValue([...objectValue, newDocument]);
    onChange([...objectValue, newDocument]);
  };

  return (
    <>
      <Select
        ref={selectRef}
        mode="multiple"
        value={objectValue?.map((item) => item.documentCode?.DE_I0200)}
        maxTagCount={10}
        tagRender={tagRender}
        popupMatchSelectWidth={500}
        style={{
          width: '100%',
          outline: isCopied ? '2px blue solid' : '',
          borderRadius: 6,
        }}
        onSelect={onSelect}
        onClick={handleClick}
        optionFilterProp="children"
        filterOption={(input, option) =>
          `${option?.value}`.startsWith(input.toUpperCase())
        }
        filterSort={(optionA, optionB) =>
          `${optionA?.value}`
            .toLowerCase()
            .localeCompare(`${optionB?.value}`.toLowerCase())
        }
        placeholder={t('Documents Type Codes')}
        showSearch
      >
        {documentTypeCodes
          ?.filter((item) => item?.code)
          ?.map((item, index) => (
            <Select.Option
              title={item.name}
              key={index}
              value={item.code}
              label={item.name}
            >
              <Space>
                <Typography.Text>{item?.code}</Typography.Text>
                <Typography.Text ellipsis type="secondary">
                  {item?.name}
                </Typography.Text>
              </Space>
            </Select.Option>
          ))}
      </Select>

      <ReferenceModal
        isModalOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        onConfirm={handleOk}
        value={selectedCode}
        onValueChange={(val) => setSelectedCode(val)}
      />
    </>
  );
};

export default DocumentsTypeCode;
