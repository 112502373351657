import { CheckOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { Button, Input, List, Popover, Typography } from 'antd';
import { alpha2ToAlpha3, alpha3ToAlpha2 } from 'i18n-iso-countries';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from 'react-simple-maps';

const alpha3 = (alpha2: string) => {
  return alpha2ToAlpha3(alpha2);
};

const geoUrl = '/world-countries-sans-antarctica.json';

interface CountryNeighbors {
  [key: string]: number;
}

interface CountryGraph {
  [key: string]: CountryNeighbors;
}

export const graph: CountryGraph = {
  DE: { AT: 1, CZ: 1.5, PL: 2, NL: 1.5, BE: 1, DK: 1.5 },
  AT: { DE: 1, SI: 1, HU: 1.5, CZ: 1.5, IT: 2, SK: 1.5, CH: 1.5 },
  CZ: { DE: 1.5, AT: 1.5, SK: 1, PL: 1 },
  PL: { DE: 2, CZ: 1, UA: 2, LT: 2, BY: 1.5, SK: 1.5 },
  SI: { AT: 1, HR: 1 },
  HR: { SI: 1, ME: 2, BA: 1 },
  HU: { AT: 1.5, RO: 2, RS: 2, SK: 1 },
  RO: { HU: 2, BG: 1.5, MD: 1.5, UA: 1.5 },
  IT: { AT: 2, SI: 2, FR: 2, CH: 1.5, SM: 1, VA: 1 },
  SK: { CZ: 1, HU: 1, PL: 1.5 },
  UA: { PL: 2, RO: 2.5, MD: 1, RU: 2 },
  ME: { HR: 2, AL: 1.5 },
  AL: { ME: 1.5, MK: 1 },
  RS: { HU: 2, BG: 1.5, BA: 1 },
  MK: { AL: 1, BG: 1, GR: 1.5 },
  BG: { RO: 1.5, RS: 1.5, MK: 1, TR: 1, GR: 1 },
  TR: { BG: 1, GR: 1.5 },
  NL: { DE: 1.5, BE: 0.5 },
  BE: { DE: 1, NL: 0.5, FR: 0.5, LU: 0.5 },
  FR: { BE: 0.5, IT: 2, ES: 2, CH: 1.5, LU: 0.5, MC: 1, AD: 1 },
  CH: { IT: 1.5, FR: 1.5, AT: 1.5, LI: 1 },
  ES: { FR: 2, PT: 1, AD: 1 },
  PT: { ES: 1 },
  LT: { PL: 2, LV: 1 },
  LV: { LT: 1, EE: 1 },
  EE: { LV: 1 },
  BY: { PL: 1.5, RU: 1.5, UA: 1.5, LT: 1.5 },
  RU: { BY: 1.5, UA: 2, EE: 2, LV: 2 },
  DK: { DE: 1.5, SE: 1 },
  SE: { DK: 1, NO: 1 },
  NO: { SE: 1 },
  LU: { BE: 0.5, FR: 0.5, DE: 1 },
  SM: { IT: 1 },
  VA: { IT: 1 },
  MC: { FR: 1 },
  AD: { FR: 1, ES: 1 },
  LI: { CH: 1 },
  GR: { MK: 1.5, BG: 1, TR: 1.5 },
  BA: { HR: 1, RS: 1 },
  MD: { RO: 1.5, UA: 1 },
};

interface Graph {
  [key: string]: {
    [key: string]: number;
  };
}

interface Previous {
  [key: string]: string | null;
}

interface QueueNode {
  node: string;
  distance: number;
  previous: Previous;
}

interface QueueNode {
  node: string;
  distance: number;
  previous: Previous;
}

const kShortestPaths = (
  graph: Graph,
  start: string,
  goal: string,
  k = 3,
  maxDepth = 100,
): string[][] => {
  const results: string[][] = [];
  const visited: Set<string> = new Set();

  const buildPath = (previous: Previous, currentNode: string): string[] => {
    const path: string[] = [currentNode];
    while (previous[currentNode]) {
      currentNode = previous[currentNode]!;
      path.unshift(currentNode);
    }
    return path;
  };

  const queue: QueueNode[] = [
    {
      node: start,
      distance: 0,
      previous: {},
    },
  ];

  while (queue.length > 0) {
    queue.sort((a, b) => a.distance - b.distance);
    const current = queue.shift()!;

    if (current.node === goal) {
      results.push(buildPath(current.previous, goal));
      if (results.length === k) {
        break;
      }
    }

    visited.add(current.node);

    if (visited.size >= maxDepth) {
      break;
    }

    for (const neighbor in graph[current.node]) {
      // eslint-disable-next-line no-prototype-builtins
      if (!graph[current.node].hasOwnProperty(neighbor)) continue;

      if (visited.has(neighbor)) continue;

      const newDistance = current.distance + graph[current.node][neighbor];
      const newPrevious: Previous = {
        ...current.previous,
        [neighbor]: current.node,
      };

      queue.push({
        node: neighbor,
        distance: newDistance,
        previous: newPrevious,
      });
    }
  }

  return results;
};

const generateRouteSuggestions = (origin: string, destination: string) => {
  if (!origin || !destination) return [];

  return [...(kShortestPaths(graph, origin, destination, 3) || [])];
};

// Add a function to generate route suggestions (mocked in this example)

export const CountrySelection = ({
  onRouteSelected,
  fromCountry,
  toCountry,
  value,
}: {
  onRouteSelected: (val: string[]) => void;
  fromCountry: string;
  toCountry: string;
  value: string[];
}) => {
  const [routeSuggestions, setRouteSuggestions] = useState<string[][]>([]);

  useEffect(() => {
    if (fromCountry && toCountry) {
      setRouteSuggestions(
        generateRouteSuggestions(
          (value?.length > 0 ? value[value?.length - 1] : fromCountry) || '',
          toCountry || '',
        )?.map((suggestion) => [
          ...(value?.length > 0 ? value : []),
          ...(suggestion || []),
        ]),
      );
    } else {
      setRouteSuggestions([]);
    }
  }, [value, fromCountry, toCountry]);

  const setSelected = (selected: string[]) => {
    onRouteSelected(
      selected.filter(function (item, pos, self) {
        return self.indexOf(item) === pos;
      }),
    );
  };

  const handleCountryClick = (geo: any) => {
    const countryCode = alpha3ToAlpha2(geo.id);
    if (!value.includes(countryCode || '')) {
      setSelected([...(value || []), countryCode || '']);
    } else {
      setSelected(value?.filter((country) => country !== countryCode));
    }
  };

  const handleRouteSubmit = () => {
    onRouteSelected(value);
    setOpen(false);
  };

  const { t } = useTranslation();

  const isSelected = (geo: any) => {
    const countryCode = alpha3ToAlpha2(geo.id || '') || '';

    return alpha3(fromCountry) === geo.id || alpha3(toCountry) === geo.id
      ? '#00FFFF'
      : value.includes(countryCode)
        ? '#FF5722'
        : routeSuggestions?.[0]?.includes(countryCode)
          ? '#b0ffff'
          : '#ECEFF1';
  };
  const mapContent = (
    <div>
      <ComposableMap
        projectionConfig={{
          center: [85, 450],
        }}
        style={{ width: '400px', height: '250px' }}
      >
        <ZoomableGroup zoom={7}>
          <Geographies geography={geoUrl}>
            {({ geographies }: { geographies: any }) =>
              geographies.map((geo: any) => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  onClick={() => handleCountryClick(geo)}
                  style={{
                    default: {
                      fill: isSelected(geo),
                      stroke: '#607D8B',
                      strokeWidth: 0.75,
                    },
                    hover: {
                      fill: '#FF5722',
                      stroke: '#607D8B',
                      strokeWidth: 0.75,
                    },
                    pressed: {
                      fill: '#FF5722',
                      stroke: '#607D8B',
                      strokeWidth: 0.75,
                    },
                  }}
                />
              ))
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
      <div>
        {routeSuggestions.length > 0 && (
          <div>
            <List>
              {routeSuggestions.map((route, index) => (
                <List.Item
                  key={index}
                  actions={[
                    <Button
                      onClick={() => {
                        setSelected(route);
                      }}
                      type="dashed"
                      icon={<CheckOutlined />}
                    >
                      {t('Apply')}
                    </Button>,
                  ]}
                >
                  <List.Item.Meta
                    title={route?.join(' > ')}
                    description={
                      <Typography.Paragraph
                        ellipsis={{
                          rows: 4,
                          expandable: true,
                        }}
                      >
                        {/* {item?.customsDescription} */}
                      </Typography.Paragraph>
                    }
                  />
                </List.Item>
              ))}
            </List>
          </div>
        )}
        <Button onClick={() => setSelected([])} style={{ marginLeft: '10px' }}>
          {t('Clear')}
        </Button>
        <Button
          type="primary"
          onClick={handleRouteSubmit}
          style={{ marginLeft: '10px' }}
        >
          {t('Submit Route')}
        </Button>
      </div>
    </div>
  );

  const [open, setOpen] = useState(false);

  return (
    <Popover
      content={mapContent}
      trigger="click"
      onOpenChange={(visible) => {
        setOpen(visible);
      }}
      open={open}
    >
      <Input
        width="100%"
        value={value?.join(' > ')}
        readOnly
        prefix={<EnvironmentOutlined />}
      />
    </Popover>
  );
};
