import { CloseOutlined } from '@ant-design/icons';
import { Button, Card, Col, Flex, Row, Space, Typography } from 'antd';
import { AggregatedCaseDataModel, CompanyModel } from 'digicust_types';
import CompanyDetailsInput from './CompanyDetailsInput';
import { useTranslation } from 'react-i18next';

const StakeholderDocumentType = ({
  value,
  onChange,
}: {
  value?: any;
  onChange: (value: AggregatedCaseDataModel) => void;
}) => {
  const updateItem = (item: AggregatedCaseDataModel) => {
    onChange(item);
  };

  const { t } = useTranslation();

  const companies = [
    { title: t('Shipper'), property: 'shipper' },
    { title: t('Consignee'), property: 'consignee' },
    { title: t('Declarant'), property: 'declarant' },
    {
      title: t('Declarant Representative'),
      property: 'declarantRepresentative',
    },
    {
      title: t('Buyer'),
      property: 'buyer',
    },
    {
      title: t('Exporter'),
      property: 'exporter',
    },
    {
      title: t('Importer'),
      property: 'importer',
    },
    { title: t('Beneficiary'), property: 'beneficiary' },
    { title: t('Broker'), property: 'broker' },
    { title: t('Carrier'), property: 'carrier' },
    { title: t('Agent'), property: 'agent' },
    { title: t('Warehouse'), property: 'warehouse' },
    { title: t('Obligater'), property: 'obligater' },
    { title: t('Contractual Partner'), property: 'contractualPartner' },
  ];

  return (
    <Space size="large" direction="vertical">
      <div style={{ padding: 15, borderRadius: 10, background: '#f7f7f7' }}>
        <Space wrap>
          {companies.map((item) => {
            const stakeholder = value?.[item.property] as CompanyModel;
            return (
              !stakeholder?.name && (
                <Button
                  size="small"
                  type="link"
                  onClick={() => {
                    updateItem({
                      ...(value || {}),
                      [item?.property]: {
                        ...(value?.[item?.property] || {}),
                        name: { value: '' },
                        userEdited: true,
                      },
                    });
                  }}
                >
                  {item?.title}
                </Button>
              )
            );
          })}
        </Space>
      </div>

      <Row gutter={[12, 12]}>
        {companies.map((item, key) => {
          const stakeholder = value?.[item.property] as CompanyModel;
          return (
            stakeholder?.name && (
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Card bordered={true} bodyStyle={{ padding: '16px' }}>
                  <Flex
                    justify="space-between"
                    align="center"
                    style={{ marginBottom: 10 }}
                  >
                    <Typography.Title level={5}>{item.title}</Typography.Title>
                    <Button
                      type="text"
                      onClick={() => {
                        updateItem({
                          ...(value || {}),
                          [item?.property]: null,
                        });
                      }}
                      icon={<CloseOutlined />}
                    />
                  </Flex>

                  <CompanyDetailsInput
                    key={key}
                    value={value?.[item.property]}
                    keyValue={item.property}
                    onChange={(stakeholderItems) => {
                      updateItem({
                        ...(value || {}),
                        [item?.property]: {
                          ...(value?.[item?.property] || {}),
                          ...(stakeholderItems || {}),
                          userEdited: true,
                        },
                      });
                    }}
                  />
                </Card>
              </Col>
            )
          );
        })}
      </Row>
    </Space>
  );
};

export default StakeholderDocumentType;
