import { Select, Space, Typography } from 'antd';
import { Procedure, ProcedureMode } from 'digicust_types';
import { BaseSelectRef } from 'rc-select';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { uniqBy } from '../../utils/helpers/uniqBy';
import { useProcedureCodes } from '../../utils/useCodes';

const ProcedureSelect = ({
  value,
  onChange = () => null,
  onCopy = () => null,
  isCopied,
}: {
  value?: Procedure;
  onChange?: (value?: Procedure) => void;
  onCopy?: () => void;
  isCopied?: boolean;
}) => {
  const { t } = useTranslation();
  const selectRef = useRef<BaseSelectRef>(null);

  const procedureCodes = useProcedureCodes();
  const [procedureCode, setProcedureCode] = useState(procedureCodes);

  const additionalCodes = useProcedureCodes();
  const [additionalCode, setAdditionalCode] = useState(additionalCodes);

  useEffect(() => {
    setProcedureCode(procedureCodes);
    setAdditionalCode(additionalCodes);
  }, [procedureCodes, additionalCodes]);

  const handleSearch = (e: string) => {
    if (e?.length == 1) {
      const filterProcedureCode = procedureCodes?.filter((procedureValue) => {
        return procedureValue?.code[0] == e;
      });
      setProcedureCode(filterProcedureCode);
    }
  };

  const handleClick = (e: React.MouseEvent) => {
    if (e.ctrlKey || e.metaKey) {
      onCopy();
      selectRef.current?.blur();
    }
  };

  const handleAdditionalSearch = (e: string) => {
    if (e?.length == 1) {
      const filterAdditionalCode = additionalCodes?.filter(
        (additionalValue) => {
          return additionalValue?.additional.substring(0, 1) == e.toUpperCase();
        },
      );
      setAdditionalCode(filterAdditionalCode);
    }
  };

  return (
    <Space.Compact
      style={{
        width: '100%',
        outline: isCopied ? '2px blue solid' : '',
        borderRadius: 6,
      }}
      onClick={handleClick}
    >
      <Select
        ref={selectRef}
        allowClear
        style={{ width: '50%' }}
        showSearch
        onSearch={handleSearch}
        placeholder={t('Procedure')}
        popupMatchSelectWidth={570}
        value={value?.procedureCodeCombination}
        onChange={(e) =>
          onChange({
            ...(procedureCodes
              .filter((item) => item.code === e)
              ?.map((item) => ({
                input: item.code,
                requestedProcedure: item.code?.substring(0, 2),
                previousProcedure: item.code?.substring(2, 4),
                value:
                  item.code?.substring(0, 1) === '1'
                    ? ProcedureMode.export
                    : ProcedureMode.import,
                procedureCodeCombination: item.code?.substring(0, 4),
              }))?.[0] || {}),
            input: e,
          })
        }
      >
        {uniqBy(procedureCode, (item) => item.code)
          ?.filter((item) => item?.code)
          ?.map((item, key) => (
            <Select.Option
              title={item.name}
              key={key}
              value={item.code}
              label={item.name}
            >
              <Space>
                <Typography.Text strong>{item.code}</Typography.Text>
                <Typography.Text
                  style={{ maxWidth: '500px', display: 'flex' }}
                  ellipsis
                  type="secondary"
                >
                  {item.name}
                </Typography.Text>
              </Space>
            </Select.Option>
          ))}
      </Select>
      <Select
        ref={selectRef}
        style={{ width: '50%' }}
        showSearch
        popupMatchSelectWidth={570}
        onSearch={handleAdditionalSearch}
        placeholder={t('Additional Code')}
        value={value?.additionalProcedure}
        onChange={(e) => onChange({ ...(value || {}), additionalProcedure: e })}
      >
        {additionalCode
          ?.filter(
            (item) =>
              item?.code === value?.procedureCodeCombination && item.additional,
          )
          ?.map((item, key) => (
            <Select.Option
              title={item?.name}
              key={key}
              value={item.additional}
              label={item.name}
            >
              <Space key={key}>
                <Typography.Text strong>{item?.additional}</Typography.Text>
                <Typography.Text
                  style={{ maxWidth: '500px', display: 'flex' }}
                  ellipsis
                  type="secondary"
                >
                  {item.name}
                </Typography.Text>
              </Space>
            </Select.Option>
          ))}
      </Select>
    </Space.Compact>
  );
};

export default ProcedureSelect;
