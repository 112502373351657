import { SwapOutlined } from '@ant-design/icons';
import { Button, Card, Radio, Space, Tag, Typography } from 'antd';
import { Rule } from 'digicust_types';
import { getColor } from '../../../../../utils/helpers/getColorFromString';
import { useTranslation } from 'react-i18next';

export default function ThenBlockCard({
  value,
  onChange,
  openInformationModal,
}: {
  value: Rule;
  onChange: (value: Rule) => void;
  openInformationModal: () => void;
}) {
  const { t } = useTranslation();

  return (
    <Card
      style={{
        borderLeft: '5px #FFA630 solid',
        minWidth: 'min-content',
      }}
    >
      <Space direction="vertical">
        <Typography.Title level={5} style={{ margin: 0, whiteSpace: 'nowrap' }}>
          {t('Then')}
        </Typography.Title>

        <Radio.Group
          onChange={(e) => {
            onChange({
              ...value,
              userInput: {
                ...(value?.userInput || {}),
                priority: e.target.value,
              },
            });
          }}
          value={value?.userInput?.priority}
        >
          <Radio.Button value="beforeNormalization">
            {t('Fallback To')}
          </Radio.Button>
          <Radio.Button value="afterNormalization">
            {t('Overwrite and Merge')}
          </Radio.Button>
          <Radio.Button value="replace">{t('Replace')}</Radio.Button>
        </Radio.Group>

        <Space style={{ marginTop: '10px' }}>
          <Button
            onClick={openInformationModal}
            icon={<SwapOutlined />}
            size="large"
          >
            {t('Edit Information')}
          </Button>
        </Space>
        {value?.userInput && (
          <Space size={[0, 8]} wrap>
            {Object.keys(value?.userInput?.annotatedAggregated || {})?.map(
              (vp) => {
                const agg = value?.userInput?.annotatedAggregated as any;

                const displayValue = (
                  Array.isArray(agg?.[vp])
                    ? agg?.[vp]?.length
                    : agg?.[vp]?.name?.value ||
                      (agg?.[vp]?.value
                        ? `${agg?.[vp]?.value}${agg?.[vp]?.unit || ''}`
                        : null) ||
                      agg?.[vp]?.input
                )
                  ?.toString()
                  ?.toUpperCase();
                return (
                  <Tag
                    color={getColor(vp)}
                    onClose={() => {
                      onChange({
                        ...value,
                        userInput: {
                          ...(value?.userInput || {}),
                          annotatedAggregated: {
                            ...(value?.userInput?.annotatedAggregated || {}),
                            [vp]: undefined,
                          },
                        },
                      });
                    }}
                    closable={true}
                  >
                    <b>{vp?.toUpperCase()}</b> {displayValue}
                  </Tag>
                );
              },
            )}
            {Object.keys(value?.userInput?.annotatedLineItem || {})?.map(
              (vp) => {
                const agg = value?.userInput?.annotatedLineItem as any;

                const displayValue = (
                  Array.isArray(agg?.[vp])
                    ? agg?.[vp]?.length
                    : agg?.[vp]?.name?.value ||
                      (agg?.[vp]?.value
                        ? `${agg?.[vp]?.value}${agg?.[vp]?.unit || ''}`
                        : null) ||
                      agg?.[vp]?.input
                )
                  ?.toString()
                  ?.toUpperCase();
                return (
                  <Tag
                    color={getColor(vp)}
                    onClose={() => {
                      onChange({
                        ...value,
                        userInput: {
                          ...(value?.userInput || {}),
                          annotatedLineItem: {
                            ...(value?.userInput?.annotatedLineItem || {}),
                            [vp]: undefined,
                          },
                        },
                      });
                    }}
                    closable={true}
                  >
                    <b>{vp?.toUpperCase()}</b> {displayValue}
                  </Tag>
                );
              },
            )}
            {Object.keys(
              value?.userInput?.annotatedExecutionStrategy || {},
            )?.map((vp) => {
              const agg = value?.userInput?.annotatedExecutionStrategy as any;

              const displayValue = (
                Array.isArray(agg?.[vp])
                  ? agg?.[vp]?.length
                  : agg?.[vp]?.name?.value ||
                    (agg?.[vp]?.value
                      ? `${agg?.[vp]?.value}${agg?.[vp]?.unit || ''}`
                      : null) ||
                    agg?.[vp]?.input
              )
                ?.toString()
                ?.toUpperCase();
              return (
                <Tag
                  color={getColor(vp)}
                  onClose={() => {
                    onChange({
                      ...value,
                      userInput: {
                        ...(value?.userInput || {}),
                        annotatedExecutionStrategy: {
                          ...(value?.userInput?.annotatedExecutionStrategy ||
                            {}),
                          [vp]: undefined,
                        },
                      },
                    });
                  }}
                  closable={true}
                >
                  <b>{vp?.toUpperCase()}</b> {displayValue}
                </Tag>
              );
            })}
          </Space>
        )}
      </Space>
    </Card>
  );
}
