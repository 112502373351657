import { Checkbox, Form, Input } from 'antd';
import { DbhSftpConfig } from 'digicust_types';
import { useTranslation } from 'react-i18next';

export default function DBHSystemForm({
  config,
  changeData,
}: {
  config: DbhSftpConfig;
  changeData: (key: string, value: string | boolean) => void;
}) {
  const { t } = useTranslation();

  return (
    <Form>
      <Form.Item label={t('Endpoint')}>
        <Input
          placeholder="https://ssl-gate.dbh.de/intercomm-t186xx"
          value={config?.['endpoint']}
          onChange={(e) => changeData('endpoint', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('Client ID')}>
        <Input
          placeholder="t186xx"
          value={config?.['client-code']}
          onChange={(e) => changeData('client-code', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('Username')}>
        <Input
          placeholder="t186xx.sstuser"
          value={config?.['user-name']}
          onChange={(e) => changeData('user-name', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('Password')}>
        <Input.Password
          placeholder={t('Password')}
          value={config?.['password']}
          onChange={(e) => changeData('password', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('Template (optional)')}>
        <Input
          placeholder={t('Template')}
          value={config?.['template']}
          onChange={(e) => changeData('template', e.target.value)}
        />
      </Form.Item>
      <Form.Item label={t('Aggregate Line Items')}>
        <Checkbox
          checked={config?.['aggregateItems']}
          onChange={(e) => changeData('aggregateItems', e.target.checked)}
        />
      </Form.Item>
      <Form.Item
        label={t(
          'Duplicate BTIs in Additional Information Field (Import only)',
        )}
      >
        <Checkbox
          checked={config?.['duplicateBTIsInAdditionalInformationField']}
          onChange={(e) =>
            changeData(
              'duplicateBTIsInAdditionalInformationField',
              e.target.checked,
            )
          }
        />
      </Form.Item>
      <Form.Item
        label={t('Aggregate Freight and Goods Invoices (Import only)')}
      >
        <Checkbox
          checked={config?.['aggregateFreightAndGoodsInvoices']}
          onChange={(e) =>
            changeData('aggregateFreightAndGoodsInvoices', e.target.checked)
          }
        />
      </Form.Item>
      <Form.Item label={t('Add statistical value (export only)')}>
        <Checkbox
          checked={config?.['addStatisticalValue']}
          onChange={(e) => changeData('addStatisticalValue', e.target.checked)}
        />
      </Form.Item>
      <Form.Item label={t('Hide item invoice value (export only)')}>
        <Checkbox
          checked={config?.['hideItemInvoiceValue']}
          onChange={(e) => changeData('hideItemInvoiceValue', e.target.checked)}
        />
      </Form.Item>
    </Form>
  );
}
