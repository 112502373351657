import { Select, Typography } from 'antd';
import { DeclarationType, Meta } from 'digicust_types';
import { useTranslation } from 'react-i18next';

const DeclarationTypeSelect = ({
  value,
  onChange = () => null,
}: {
  value?: Meta<DeclarationType>;
  onChange?: (value?: Meta<DeclarationType>) => void;
}) => {
  const { t } = useTranslation();

  const options = [
    {
      label: 'Pre-Declaration',
      value: DeclarationType.preDeclaration,
    },
    {
      label: 'Declaration',
      value: DeclarationType.declaration,
    },
  ];
  return (
    <Select
      showSearch
      style={{ width: '100%' }}
      placeholder={t('Select Declaration Type')}
      value={value?.value}
      onChange={(e) => {
        const option = options?.find((option) => option?.value === e);
        onChange(option);
      }}
    >
      {options?.map((item, key) => (
        <Select.Option title={item?.label} key={key} value={item?.value}>
          <Typography.Text>{item?.label}</Typography.Text>
        </Select.Option>
      ))}
    </Select>
  );
};

export default DeclarationTypeSelect;
