import { Input, InputRef } from 'antd';
import { Ref, useEffect, useState } from 'react';

const NumberInput = ({
  value,
  onChange,
  placeholder,
  ref,
  id,
}: {
  value?: number;
  placeholder?: string;
  onChange?: (e?: number) => void;
  ref?: Ref<InputRef>;
  id?: string;
}) => {
  const [curValue, setCurValue] = useState<number>();
  useEffect(() => setCurValue(value), [value]);

  return (
    <Input
      ref={ref}
      id={id}
      type="number"
      placeholder={placeholder}
      title={curValue?.toString()}
      value={curValue}
      onChange={(e) => setCurValue(Number(e.target.value))}
      onBlur={() => onChange && onChange(curValue)}
      min={0}
    />
  );
};

export default NumberInput;
