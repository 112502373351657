import { Select } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { DigicustDocumentType } from 'digicust_types';
import React from 'react';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

interface DocumentTypeSelectProps {
  value?: DigicustDocumentType;
  disabled?: boolean;
  onChange: (value: DigicustDocumentType) => void;
  key?: string;
  style?: React.CSSProperties;
  size?: SizeType;
}

export const DocumentTypeSelect: React.FC<DocumentTypeSelectProps> = ({
  value,
  disabled = false,
  onChange,
  style,
  size,
}) => {
  const { t } = useTranslation();

  return (
    <Select
      size={size}
      style={style}
      disabled={disabled}
      showSearch
      value={value}
      dropdownStyle={{ minWidth: '200px' }}
      placeholder="Select a type"
      optionFilterProp="children"
      onChange={onChange}
      filterOption={(input, option) =>
        (option!.children as unknown as string)
          .toLowerCase()
          .includes(input.toLowerCase())
      }
    >
      <Option value={DigicustDocumentType.ToBeClassified}>
        {t('To Be Classified')}
      </Option>
      <Option value={DigicustDocumentType.Invoice}>{t('Invoice')}</Option>
      <Option value={DigicustDocumentType.Waybill}>{t('Waybill')}</Option>
      <Option value={DigicustDocumentType.DeliveryNote}>
        {t('Delivery Note')}
      </Option>
      <Option value={DigicustDocumentType.ExportDeclaration}>
        {t('Export Declaration')}
      </Option>
      <Option value={DigicustDocumentType.TransitDeclaration}>
        {t('Transit Declaration')}
      </Option>
      <Option value={DigicustDocumentType.TurkishExportDeclaration}>
        {t('Turkish Export Declaration')}
      </Option>
      <Option value={DigicustDocumentType.ATR}>{t('A.TR')}</Option>
      <Option value={DigicustDocumentType.PackingList}>
        {t('Packing List')}
      </Option>
      <Option value={DigicustDocumentType.TemporaryStorage}>
        {t('Temporary Storage')}
      </Option>
      <Option value={DigicustDocumentType.NoProcessing}>
        {t('No Processing (Ignore)')}
      </Option>
    </Select>
  );
};
