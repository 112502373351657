import { PageHeader } from '@ant-design/pro-layout';

export default function PageLayout({
  children,
  title = '',
  subTitle = '',
  extra,
}: {
  children: React.ReactNode;
  title?: string | null;
  subTitle?: string | null;
  extra?: React.ReactNode;
}) {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        minHeight: '100vh',
        maxHeight: '100vh',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <PageHeader
        title={
          !title ? null : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <h3 style={{ margin: 0 }}>{title}</h3>

              <p
                style={{
                  margin: '5px 0 0 0',
                  fontSize: '14px',
                  opacity: '60%',
                }}
              >
                {subTitle}
              </p>
            </div>
          )
        }
        extra={extra}
        style={{ background: 'white', position: 'sticky', top: 0, zIndex: 20 }}
      />

      {children}
    </div>
  );
}
