import { Select, Space, Typography } from 'antd';
import { PackageType } from 'digicust_types';
import { useEffect, useState } from 'react';
import { StakeholderRepository } from '../../utils/repositories/stakeholder.repository';
import { useTranslation } from 'react-i18next';

const PackageTypeSelect = ({
  value,
  onChange,
}: {
  value?: PackageType;
  onChange?: (value?: PackageType) => void;
}) => {
  const { t } = useTranslation();
  const [packagesList, setPackagesList] = useState<PackageType[]>([]);
  // TODO: Cache the package types!
  const getPackages = async () => {
    const response: any = await StakeholderRepository.getPackagesList('en');
    setPackagesList(response?.data?.packages || []);
  };
  useEffect(() => {
    getPackages();
  }, []);

  return (
    <Select
      style={{ width: '100%' }}
      popupMatchSelectWidth={400}
      showSearch
      placeholder={t('Package Type')}
      value={value?.code}
      onChange={(e) => {
        onChange &&
          onChange({
            ...(packagesList?.find((item) => item?.code === e) || {}),
            input: e,
          });
      }}
    >
      {packagesList?.map((item: PackageType, key) => (
        <Select.Option
          title={item?.germanType}
          key={key}
          value={item?.code}
          label={item?.type}
        >
          <Space>
            <Typography.Text
              style={{ maxWidth: '400px', display: 'flex' }}
              ellipsis
            >
              {item?.code} {item?.type}
            </Typography.Text>
          </Space>
        </Select.Option>
      ))}
    </Select>
  );
};

export default PackageTypeSelect;
