import { Input, Space } from 'antd';
import { Meta, UnitOfMeasurement } from 'digicust_types';
import React, { useEffect, useState } from 'react';
import { UnitOfMesurementSelect } from './UnitOfMesurementSelect';
import { useTranslation } from 'react-i18next';

const SpecialMeasureUnit = ({
  itemSpecialUnitMeasure,
  itemSpecialUnit,
  onChange = () => null,
}: {
  itemSpecialUnitMeasure?: Meta<number>;
  itemSpecialUnit?: UnitOfMeasurement;
  onChange?: ({
    itemSpecialUnitMeasure,
    itemSpecialUnit,
  }: {
    itemSpecialUnitMeasure?: Meta<number>;
    itemSpecialUnit?: UnitOfMeasurement;
  }) => void;
  onCopy?: () => void;
  isCopied?: boolean;
}) => {
  const { t } = useTranslation();
  const [currentValue, setCurrentValue] = useState<number>();

  useEffect(() => {
    setCurrentValue(itemSpecialUnitMeasure?.value);
  }, [itemSpecialUnitMeasure?.value]);

  return (
    <Space.Compact style={{ width: '100%', borderRadius: 6 }}>
      <Input
        style={{ width: '100%' }}
        placeholder={t('Special Measure')}
        type="number"
        value={currentValue}
        onChange={(e) => {
          setCurrentValue(Number(e.target.value));
        }}
        onBlur={() =>
          onChange({
            itemSpecialUnitMeasure: {
              ...(itemSpecialUnitMeasure || {}),
              value: Number(currentValue),
              input: currentValue?.toString(),
            },
          })
        }
      />
      <UnitOfMesurementSelect
        value={itemSpecialUnit}
        onChange={(e) =>
          onChange({
            itemSpecialUnit: { ...(e || {}), userEdited: true },
          })
        }
      />
    </Space.Compact>
  );
};

export default SpecialMeasureUnit;
